<template>
  <div class="container">
    <div class="db-RegisterAndLoginBackground-background">
      <div class="db-RegisterAndLoginBackground-grayBackground"></div>
      <div class="db-RegisterAndLoginBackground-verticalLine db-RegisterAndLoginBackground-verticalLine--first"></div>
      <!-- <div class="db-RegisterAndLoginBackground-verticalLine db-RegisterAndLoginBackground-verticalLine--second"></div> -->
      <!-- <div class="db-RegisterAndLoginBackground-verticalLine db-RegisterAndLoginBackground-verticalLine--third"></div> -->
      <!-- <div class="db-RegisterAndLoginBackground-verticalLine db-RegisterAndLoginBackground-verticalLine--fourth"></div> -->
      <div class="db-RegisterAndLoginBackground-verticalLine db-RegisterAndLoginBackground-verticalLine--fifth"></div>
      <div class="db-RegisterAndLoginBackground-firstLeftStripe" style="background: rgb(128, 233, 255)"></div>
      <div class="db-RegisterAndLoginBackground-secondLeftStripe" style="background: rgb(122, 115, 255)"></div>
      <div class="db-RegisterAndLoginBackground-firstAndSecondLeftStripeBlend" style="background: rgb(0, 72, 229)">
      </div>
      <div class="db-RegisterAndLoginBackground-firstRightStripe" style="background: rgb(122, 115, 255)"></div>
    </div>
    <!-- 表单 -->
    <div class="db-RegisterAndLoginLayout-contentWrapper Box-root">
      <div class="db-RegisterAndLoginLayout-formContainer Box-root">
        <!-- <div class="db-RegisterAndLoginLayout-logo Box-root">
          <div title="Stripe"
            class="SVGInline SVGInline--cleaned SVG Logo Icon-color Icon-color--gray800 Box-root Flex-flex">
            LIMAO INC
          </div>
        </div> -->
        <!-- form -->
        <div class="db-RegisterAndLoginLayout-form">
          <!-- 登录表单 -->
           <div class="db-RegisterAndLoginLayout-form-row1">注册您的即时账户</div>
           <!-- 用户名 -->
            <div class="db-RegisterAndLoginLayout-form-row2">
              <div class="db-RegisterAndLoginLayout-form-row2-label">手机号</div>
              <el-input style="margin-top: 12px;" v-model="form_reg.phonenumber"></el-input>
            </div>
             <!-- 密码 -->
            <div class="db-RegisterAndLoginLayout-form-row2 martop--32">
              <div class="db-RegisterAndLoginLayout-form-row2-label">
                <span>密码</span>
              </div>
              <el-input style="margin-top: 12px;" type="password" v-model="form_reg.password" ></el-input>
            </div>
            <!-- 密码 -->
            <div class="db-RegisterAndLoginLayout-form-row2 martop--32">
              <div class="db-RegisterAndLoginLayout-form-row2-label">
                <span>确认密码</span>
              </div>
              <el-input style="margin-top: 12px;" type="password" v-model="form_reg.Rpassword" ></el-input>
            </div>
            <div class="db-RegisterAndLoginLayout-form-row2 martop--32">
              <div class="db-RegisterAndLoginLayout-form-row2-label">
                <span>邀请码</span>
              </div>
              <el-input style="margin-top: 12px;" v-model="form_reg.inviteCode" ></el-input>
            </div>
            <!-- 姓名 -->
            <div class="db-RegisterAndLoginLayout-form-row2 martop--32">
              <div class="db-RegisterAndLoginLayout-form-row2-label">
                <span>姓名</span>
              </div>
              <el-input style="margin-top: 12px;" v-model="form_reg.realname" ></el-input>
            </div>
            <!-- 身份证 -->
            <div class="db-RegisterAndLoginLayout-form-row2 martop--32">
              <div class="db-RegisterAndLoginLayout-form-row2-label">
                <span>身份证</span>
              </div>
              <el-input style="margin-top: 12px;" v-model="form_reg.realcard" ></el-input>
            </div>
            <!-- 验证码 -->
            <div class="db-RegisterAndLoginLayout-form-row3 martop--32">
              <div class="db-RegisterAndLoginLayout-form-row3-label">
                <span>验证码</span>
              </div>
              <div class="db-RegisterAndLoginLayout-form-row3-code">
                <el-input style="margin-top: 12px;width: 45%;" v-model="form_reg.code"></el-input>
                <img class="codeImg" :src="codeSrc" alt="" @click="getCodeUrl()">
              </div>
            </div>
            <!-- 用户协议 -->
            <div class="db-RegisterAndLoginLayout-form-row5">
              <div class="reg_right">
            <el-checkbox size="medium" v-model="agr"></el-checkbox>
            阅读并同意
            <!-- 注册协议 -->
            <el-link style="margin-left: 0px; font-size: 15px;" type="info"
              @click="centerDialogVisible = true">
              《注册协议》
            </el-link>
            <!-- 隐私协议 -->
            <el-link style="margin-left: 0px; font-size: 15px; " type="info"
              @click="ysVisible = true">
              《隐私协议》
            </el-link>
            <!-- 保密协议 -->
            <el-link style="margin-left: 0px; font-size: 15px;" type="info"
              @click="tkVisible = true">
              《保密协议》
            </el-link>
          </div>
            </div>
            <!-- 按钮 -->
             <div class="db-RegisterAndLoginLayout-form-row4 ">
                <div class="db-RegisterAndLoginLayout-form-row4-btn" @click="register">确定</div>
             </div>
             <!-- 注册 -->  
            <div class="db-RegisterAndLoginLayout-foot">
                <span>已有账户？</span><span @click="go_login()" class="btn_foot">立即登录</span>
            </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="centerDialogVisible" width="50%" center top="5vh">
      <div class="logo">
        <img src="../images/chip.png" alt="" />
        <div class="companyName">LI MAO INC</div>
      </div>
      <div class="content-title">
        Registration Agreement
      </div>
      <div style="height: 60vh; overflow-y: auto">
        <h2>
          By using our Website, you confirm that you accept these terms, and that you agree to comply with them. We
          recommend that you read these terms carefully (in particular section 6 on liability) and print a copy for
          future reference. If there is anything within these terms that you do not understand, please contact us using
          the details in section 1. If you do not agree to these terms, you must not use our Website.
        </h2>
        <h3>
          You warrant that you have capacity to, on behalf of your Business Firm, enter into these terms and complete
          the registration and know-your-customer documentation submission procedures on our Website and that you will
          only use our Website to apply for the Services on behalf of your Business Firm in a legitimate manner.
        </h3>
        <h3>
          We may amend these terms from time to time. We will note the date that amendments were last made at the top of
          these terms, and any amendments will take effect upon posting. Every time you wish to use our Website, please
          check these terms to ensure you understand the terms that apply at that time.
        </h3>
        <h3>
          1).RULES WHEN USING OUR WEBSITE
        </h3>
        <p>WE MAY MAKE CHANGES TO OUR WEBSITE </p>
        <ul>
          <li>
            We may update and change our Website from time to time to reflect changes to our Services, our users’ needs
            and our business priorities. We will try to give you reasonable notice of any major changes.
          </li>
        </ul>
        <p>WE MAY SUSPEND OR WITHDRAW OUR WEBSITE </p>
        <ul>
          <li>
            Our Website is made available free of charge.
          </li>
          <li>
            We do not guarantee that our Website, or any content on it, will always be available or be uninterrupted. We
            may suspend, withdraw, or restrict the availability of all or any part of our Website for business and
            operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.
          </li>
          <li>
            The provision of our Website is reliant on the internet and devices. You fully understand and agree that we
            will not be liable for any losses suffered by you as a result of our Website not being available due to
            events, circumstances or causes beyond our reasonable control, including but not limited to internet, system
            or device instability, computer viruses and hacker attacks.
          </li>
          <li>
            You are responsible for providing the necessary equipment (including but not limited to an appropriate
            device and internet connection) and ensuring that all persons who access our Website through your internet
            connection are aware of these terms and other applicable terms and conditions, and that they comply with
            them.
          </li>
        </ul>
        <p>YOUR ACCOUNT DETAILS</p>
        <ul>
          <li>
            You warrant that all account information supplied by you on behalf of your Business Firm is true, accurate,
            current and complete.
          </li>
          <li>
            If you choose, or you are provided with, a user identification code, password or any other piece of
            information as part of our security procedures, you must treat such information as confidential. You must
            not disclose it to any third party. You fully understand and agree that we will not be liable for any losses
            suffered by you or your Business Firm as a result of you failing to treat such information as confidential.
          </li>
          <li>
            We have the right to disable any user identification code or password, whether chosen by you or allocated by
            us, at any time, if in our reasonable opinion you or your Business Firm has failed to comply with any of
            these terms.
          </li>
          <li>
            If you or your Business Firm knows or suspects that anyone other than you or your Business Firm knows your
            user identification code or password, you must promptly notify us using the contact details in section 1 of
            these terms.
          </li>
        </ul>
        <p>HOW YOU MAY USE MATERIAL ON OUR WEBSITE </p>
        <ul>
          <li>
            We are the owner or the licensee of all patents, copyright, trademarks, business names and domain names,
            rights in designs, rights in computer software, database rights and all other intellectual property rights,
            in each case whether registered or unregistered, in our Website. All such rights are reserved.
          </li>
          <li>
            Neither you nor your Business Firm may modify the paper or digital copies of any materials you or your
            Business Firm has printed off or downloaded in any way, and neither you nor your Business Firm may use any
            illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.
          </li>
          <li>
            Our status (and that of any identified contributors) as the authors of content on our Website must always be
            acknowledged.
          </li>
          <li>
            Neither you nor your Business Firm may use any part of the content on our Website for commercial purposes
            without obtaining a licence to do so from us or our licensors.
          </li>
          <li>
            If you or your Business Firm prints off, copies or downloads any part of our Website in breach of these
            terms, your and your Business Firm’s rights to use our Website will cease immediately and you and your
            Business Firm must, at our option, return or destroy any copies of the materials you have made.
          </li>
        </ul>
        <p>WE ARE NOT RESPONSIBLE FOR WEBSITES WE LINK TO</p>
        <ul>
          <li>
            Where our Website contains links to other sites and resources provided by third parties, these links are
            provided for your information only. Such links should not be interpreted as approval by us of those linked
            websites or information you may obtain from them.
          </li>
          <li>
            We have no control over the contents of those sites or resources.
          </li>
        </ul>
        <p>PROHIBITED USES</p>
        <ul>
          <li>
            <p>You and your Business Firm may only use our Website for lawful purposes. Neither you nor your Business
              Firm may use our Website:</p>
            <ul>
              <li> In any way that breaches any applicable local, national or international law or regulation.</li>
              <li>In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect.</li>
              <li>For the purpose of harming or attempting to harm minors in any way.</li>
              <li>To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional
                material or any other form of similar solicitation (spam).</li>
              <li>To knowingly transmit any data, send or upload any material that contains viruses, trojan horses,
                worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer
                code designed to adversely affect the operation of any computer software or hardware.</li>
            </ul>
            <p>You and your Business Firm also agree:</p>
            <ul>
              <li>Not to reproduce, duplicate, copy or re-sell any part of our Website in contravention with these
                terms.</li>
              <li>Not to access without authority, interfere with, damage or disrupt:</li>
              <ul>
                <li>any part of our Website;</li>
                <li>any equipment or network on which our Website is stored;</li>
                <li>any software used in the provision of our Website; or</li>
                <li>any equipment or network or software owned or used by any third party.</li>
              </ul>
              <li>
                Not to interfere or attempt to interfere with the normal operation of our Website or any activity that
                is conducted on our Website.
              </li>
              <li>
                Not to take any action that would result in an unreasonably large data load on our Website’s network.
              </li>
            </ul>
          </li>
        </ul>
        <p>WE ARE NOT RESPONSIBLE FOR VIRUSES AND YOU MUST NOT INTRODUCE THEM</p>
        <ul>
          <li>
            We do not guarantee that our Website will be secure or free from bugs or viruses.
          </li>
          <li>
            You and your Business Firm are responsible for configuring your information technology, computer programmes
            and platform to access our Website. You and your Business Firm should use your own virus protection
            software.
          </li>
          <li>
            Neither you nor your Business Firm may misuse our Website by knowingly introducing viruses, trojans, worms,
            logic bombs or other material that is malicious or technologically harmful. Neither you nor your Business
            Firm may attempt to gain unauthorised access to our Website, the server on which our Website is stored or
            any server, computer or database connected to our Website. Neither you nor your Business Firm may attack our
            Website via a denial-of-service attack or a distributed denial-of service attack.
          </li>
        </ul>
        <p>RULES ABOUT LINKING TO OUR WEBSITE</p>
        <ul>
          <li>
            You and your Business Firm may link to our home page, provided you and your Business Firm do so in a way
            that is fair and legal and does not damage our reputation or take advantage of it.
          </li>
          <li>
            Neither you nor your Business Firm may establish a link in such a way as to suggest any form of association,
            approval or endorsement on our part where none exists.
          </li>
          <li>
            Neither you nor your Business Firm may establish a link to our Website in any website that is not owned by
            you or your Firm (as applicable).
          </li>
          <li>
            Our Website must not be framed on any other site, nor may you or your Business Firm create a link to any
            part of our Website other than the home page.
          </li>
          <li>
            We reserve the right to withdraw linking permission without notice.
          </li>
        </ul>
        <p>Financial Crime Compliance</p>
        <ul>
          <li>
            <p>We are required to act in accordance with applicable laws, regulations, policies (including our policies)
              and request of statutory and regulatory authorities operating in various jurisdictions. These relate,
              amongst other things, to the prevention of money laundering, terrorist financing, bribery, corruption,
              actual or attempted tax evasion, fraud and the provision of financial or other services to any persons
              which may be subject to sanctions. We may in our absolute discretion take any action as we consider
              appropriate to comply with all such laws, regulations, policies and requests. Such action may include but
              is not limited to:</p>
            <ul>
              <li>
                screening, intercepting and investigating any instruction, drawdown request, application for services,
                payment or communication sent to or by you or your Business Firm (or on your or your Business Firm’s
                behalf) and to or from your or your Business Firm’s account;
              </li>
              <li>
                investigating and making further enquiries as to the source of or intended recipient of funds, the
                status and identity of a person or entity, whether they are subject to a sanction regime, and whether a
                name which might refer to a sanctioned person actually refers to that person;
              </li>
              <li>
                combining and using information about you or your Business Firm, your personal data, beneficial owners,
                authorised signatories and other representatives, accounts, transactions, use of our services with other
                related information possessed by us or our affiliates;
              </li>
              <li>
                delaying, blocking, suspending or refusing to process any payment or instruction to you or your Business
                Firm or by you or your Business Firm in our absolute discretion;
              </li>
              <li>
                refusing to enter or conclude transactions involving certain persons or entities;
              </li>
              <li>
                terminating our relationship with you or your Business Firm;
              </li>
              <li>
                reporting suspicious transactions to any authority; and
              </li>
              <li>
                taking any other actions necessary for us or our affiliates to meet any legal, regulatory or compliance
                obligations.
              </li>
            </ul>
          </li>

          <li>
            To the extent permissible by law, neither us nor any of our agents shall be liable for any loss (whether
            direct or consequential and including without limitation, loss of profit or interest) or damage suffered by
            you or your Business Firm or any third party, caused in whole or in part in connection with Financial Crime
            Compliance. For the purpose of this section 4, “Financial Crime Compliance” means any action to meet the
            compliance obligations relating to detection or prevention of financial crime that we may take.
          </li>

        </ul>
        <h3>
          2).HOW WE MAY USE YOUR PERSONAL INFORMATION
        </h3>
        <p>We will only use your personal information as set out in our Privacy Policy.</p>
        <h3>3).LIABILITY</h3>
        <p>
          OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU
        </p>
        <ul>
          <li>
            <p>WE WILL ENDEAVOUR TO ENSURE THAT THE INFORMATION AVAILABLE ON OUR WEBSITE IS ACCURATE AND COMPLETE,
              HOWEVER TO THE EXTENT PERMITTED BY LAW, WE SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE INCURRED BY YOU AS A
              RESULT OF THE INACCURACY OR INCOMPLETENESS OF THE INFORMATION.

              NO REPRESENTATIONS, WARRANTIES OR CONDITIONS ARE GIVEN BY US IN RESPECT OF ANY INFORMATION WHICH IS
              PROVIDED TO YOU ON OUR WEBSITE AND ANY SUCH REPRESENTATIONS, WARRANTIES OR CONDITIONS ARE EXCLUDED, SAVE
              TO THE EXTENT THAT SUCH EXCLUSION IS PROHIBITED BY LAW.

              TO THE EXTENT PERMITTED BY LAW, WE SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE INCURRED BY YOU OR YOUR
              BUSINESS FIRM AS A RESULT OF:</p>
            <ul>
              <li>
                INCORRECT DETAILS (SUCH AS NAME, ID DETAILS, NATIONALITY, CONTACT DETAILS, CORPORATE NAME AND CORPORATE
                DOCUMENTS) SUBMITTED BY YOU OR YOUR BUSINESS FIRM. THIS INCLUDES, BUT IS NOT LIMITED TO, LOSS OR DAMAGE
                AS A RESULT OF NOT BEING ABLE TO CONTACT YOU OR YOUR BUSINESS FIRM WITH IMPORTANT INFORMATION ABOUT YOUR
                OR YOUR BUSINESS FIRM’S USE OF OUR WEBSITE, WHERE YOU OR YOUR BUSINESS FIRM HAS PROVIDED INCORRECT
                CONTACT DETAILS.
              </li>
              <li>
                THE RELEVANT SERVICES PROVIDER DETERMINING NOT TO OR CEASING TO PROVIDE YOU THE SERVICES FOR ANY REASON
                WHATSOEVER.
              </li>
              <li>
                ANY DIRECT COMMUNICATIONS BETWEEN YOU AND THE RELEVANT SERVICE PROVIDER.
              </li>
              <li>
                FALSE, INACCURATE, OUTDATED OR INCOMPLETE INFORMATION PROVIDED BY YOU AND/OR YOUR BUSINESS FIRM.
              </li>
              <li>
                <p>EVENTS OUTSIDE OF OUR CONTROL, INCLUDING BUT NOT LIMITED TO:</p>
                <ul>
                  <li>
                    ACTS OF GOD, FLOOD, DROUGHT, EARTHQUAKE OR OTHER NATURAL DISASTER;
                  </li>
                  <li>
                    EPIDEMIC OR PANDEMIC;
                  </li>
                  <li>
                    TERRORIST ATTACK, WAR, COMMOTION OR RIOTS, THREAT OF OR PREPARATION FOR WAR, ARMED CONFLICT,
                    IMPOSITION OF SANCTIONS, EMBARGO, OR BREAKING OFF OF DIPLOMATIC RELATIONS;
                  </li>
                  <li>
                    NUCLEAR, CHEMICAL OR BIOLOGICAL CONTAMINATION OR SONIC BOOM;
                  </li>
                  <li>
                    ANY LAW OR ANY ACTION TAKEN BY A GOVERNMENT OR PUBLIC AUTHORITY; AND
                  </li>
                  <li>
                    INTERNET, SYSTEM OR DEVICE INSTABILITY, COMPUTER VIRUSES AND HACKER ATTACKS.
                  </li>
                </ul>
              </li>

            </ul>
          </li>
          <li>
            WE HAVE NO LIABILITY TO YOU FOR ANY LOSS OF PROFIT, LOSS OF BUSINESS, BUSINESS INTERRUPTION, OR LOSS OF
            BUSINESS OPPORTUNITY.
          </li>
        </ul>
        <p>BREACH OF THESE TERMS BY YOU</p>
        <ul>
          <li>You and your Business Firm are responsible for any losses suffered by us as a result of any use by you
            and/or your Business Firm of our Website which is not in accordance with these terms.</li>
          <li>
            <p>Failure to comply with these terms may result in our taking all or any of the following actions:</p>
            <ul>
              <li>Immediate, temporary or permanent withdrawal of your and your Business Firm’s right to use our
                Website.</li>
              <li>Issue of a warning to you and your Business Firm.</li>
              <li> Legal proceedings against you and/or your Business Firm for reimbursement of all costs on an
                indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting
                from the breach.</li>
              <li>Further legal action against you and your Business Firm.</li>
              <li> Disclosure of such information to law enforcement authorities as we reasonably feel is necessary or
                as required by law. </li>
            </ul>
          </li>
        </ul>
        <h3>4).COMPLAINTS</h3>
        <p>If you have any questions or complaints about our Website, please contact us using the details in section 1
          of these terms.</p>
        <h3>5).GOVERNING LAW AND ARBITRATION</h3>
        <p>
          These terms are governed by the laws of the Republic of Singapore. Any dispute arising out of or in connection
          with these terms, including any question regarding their existence, validity or termination, shall be referred
          to and finally resolved by arbitration administered by the Singapore International Arbitration Centre (“SIAC”)
          in accordance with the Arbitration Rules of the Singapore International Arbitration Centre (“SIAC Rules”) for
          the time being in force, which rules are deemed to be incorporated by reference in this clause. The seat of
          the arbitration shall be Singapore. The Tribunal shall consist of three arbitrators. The language of the
          arbitration shall be English.
        </p>
        <h3>6).LANGUAGE</h3>
        <p>
          Where the original English version of these terms has been translated into other languages, the English
          version shall prevail in the event of any inconsistences between the versions.
        </p>
        <h3>
          7).GENERAL
        </h3>
        <ul>
          <li>
            These terms are between you, your Business Firm and us. No other person shall have any rights to enforce any
            of these terms.
          </li>
          <li>
            If any court or relevant authority finds that part of these terms are illegal, the rest will continue in
            full force and effect.
          </li>
          <li>
            Even if we delay enforcing these terms, we may still take steps against you and/or your Business Firm at a
            later date.
          </li>
        </ul>
      </div>
      <!-- <el-button plain @click="centerDialogVisible= false">关 闭</el-button> -->
    </el-dialog>
    <el-dialog :visible.sync="ysVisible" width="50%" center top="5vh">
      <div class="logo">
        <img src="../images/chip.png" alt="" />
        <div class="companyName">LI MAO INC</div>
      </div>
    
      <div class="content-title">
        Privacy Agreement
      </div>
      
      <div style="height: 60vh; overflow-y: auto">
        <h1>Dear user, welcome to read this agreement:</h1>
        <p>
          Your privacy is important to us ("us" or "ours"). And we are committed
          to protecting your privacy. Therefore, we have established a privacy
          policy. This Privacy Policy ("Privacy Policy") explains how we handle
          your information and protect your privacy when you use websites and
          services, as well as the available rights and options related to your
          information. We believe that you have the right to learn about the
          practices we may collect and use when using websites and services
          ("Websites and Services"). If you do not agree to the terms and
          conditions set here, please do not use this website and services.
        </p>
        <h3>
          1.The information we process and the purpose of processing your
          information
        </h3>
        <p>
          We will handle two types of information. Our mission is to
          continuously improve our website and services, and provide you with
          new or better experiences. As part of this task, we will use your
          information for the following purposes. If there are any new purposes
          for processing your personal data, we will notify you by making
          corresponding changes to this privacy policy before starting to
          process information related to that new purpose.
        </p>
        <ul>
          <li>
            <p>1.1The information you submitted</p>
            <ul>
              <li>
                (1)ID card and photos (excluding facial photos). You can
                voluntarily grant us access to the camera or album to obtain
                photos on your mobile device. For such photos, we will neither
                store nor share them with any third party. This information is
                necessary for the proper performance of the contract between you
                and us.
              </li>
              <li>
                (2)Facial feature data. When using facial recognition function
                for the first time, you can voluntarily grant us access to the
                camera and album to obtain facial photos on mobile devices. We
                can only access your camera and album if you confirm your
                permission. Then, we will use third-party provided SDK to
                analyze facial feature data, identify the positions and shapes
                of the five senses in the photo, process the photo, and provide
                feedback on the rendering to you. This rendering will not
                contain any personal identification information. For such data,
                photos, and renderings, we neither store nor share them with any
                third party. The purpose of processing such information is only
                to operate the facial recognition function of the application
                (including but not limited to aging effects, gender
                transformation, facial fusion, etc.).
              </li>
              <li>
                (3)The purpose of processing such information is only to operate
                the facial recognition function of the application (including
                but not limited to aging effects, gender conversion effects,
                facial fusion effects, face changing effects, filter effects),
                cameras, and photos. In order to use certain features of this
                application, you may voluntarily grant us access to the camera
                or album to obtain photos on your mobile device. We may use
                Photo Kit API to collect photos, images, and other data that you
                upload or submit while using the application. This information
                is necessary for the proper performance of the contract between
                you and us.
              </li>
              <li>
                (4)Supporting relevant information. If you contact us through
                our support section or via email, we may collect your contact
                information, your complaints or feedback, and any information
                you voluntarily provide related to this support. This type of
                information is only used to provide you with support services
                and will not be associated with any other information collected
                from you through the website and services.
              </li>
              <li>
                <p>(5)The purpose of the information you submitted is：</p>
                <ul>
                  <li>1)Operate and update websites and services；</li>
                  <li>
                    2)Improving and customizing websites, services, and related
                    services；
                  </li>
                  <li>
                    3)Maintain, test, and monitor the quality and operation of
                    the website；
                  </li>
                  <li>
                    4)To provide you with support and handle your complaints
                    and/or feedback；
                  </li>
                  <li>
                    5)Take any measures in disputes related to your website and
                    services；
                  </li>
                  <li>
                    6)Comply with and comply with any applicable laws,
                    regulations, legal procedures, or enforceable government
                    requirements。
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <p>1.2Automatically processed information</p>
            <ul>
              <li>
                <p>
                  (1)When you use websites and services, we will collect the
                  following information：
                </p>
                <ul>
                  <li>
                    -Universal Unique identifier (UUID), the type of mobile
                    device and the application and operating system version
                    installed on the mobile device；
                  </li>
                  <li>-Country, Language；</li>
                </ul>
              </li>
              <li>
                (2)Metadata. Metadata can describe the location information and
                file creation time contained in photos or other files, as well
                as the rough characteristics of characters in photos and other
                content contained in photos. We use photo metadata to provide
                you with certain features, including precise facial recognition,
                keypoint recognition, and other custom features based on these
                basic features.
              </li>
              <li>
                <p>
                  (3)Payment data. We may collect information about your
                  purchase order, such as purchasing goods, ordering time, order
                  status, and payment time. We do not collect, nor are we
                  responsible for collecting payment details or ensuring
                  security. Payment information is processed and stored by a
                  third-party company. Before entering your personal payment
                  details, we recommend that you read and familiarize yourself
                  with the privacy policies of these third-party companies. When
                  you start using websites and services, information will be
                  automatically processed through automated data processing
                  technology. The purpose of automatically processed information
                  is to:
                </p>
                <ul>
                  <li>
                    1)Operate and update websites and services, and provide you
                    with existing and new features and features;
                  </li>
                  <li>
                    2)Improving and customizing websites, services, and related
                    services；
                  </li>
                  <li>
                    3)Maintain, test, monitor, and improve the quality and
                    operation of the website；
                  </li>
                  <li>
                    4)Enforce the terms and conditions applicable to websites
                    and services, and prevent, detect, and investigate fraud,
                    security vulnerabilities, potential prohibited or illegal
                    activities and their abuse, protect our trademarks, and
                    enforce our terms of use.
                  </li>
                </ul>
              </li>
              <li></li>
            </ul>
          </li>
          <li></li>
          <li></li>
        </ul>
        <h3>2.Protecting personal information</h3>
        <ul>
          <li>
            We take preventive measures, including administrative, technical,
            and physical measures, to protect your personal information from
            loss, theft, abuse, and unauthorized access, disclosure, alteration,
            and destruction.
          </li>
          <li>
            Ensure the security of your personal information; We will
            communicate our privacy and security guidelines to all employees and
            strictly implement internal privacy protection measures within the
            company.
          </li>
          <li>
            Unfortunately, neither the transmission method nor the electronic
            storage method on the Internet is 100% secure. We do our best to
            protect your personal information, but we cannot guarantee its
            absolute security. If your personal information is stolen due to
            security breaches, we will immediately notify you in accordance with
            applicable laws.
          </li>
          <li>
            If you have any questions about the security of our website and
            services, you can contact us through the email shown below.
          </li>
        </ul>
        <h3>3.Sharing information with third parties</h3>
        <p>
          Except for the following events, we will not share any personally
          identifiable information we have collected from you：
        </p>
        <ul>
          <li>
            3.1 If the law requires us to disclose your information, we may
            share your information with law enforcement agencies or other
            competent authorities and any third party as necessary (such as
            detecting, preventing, or otherwise addressing fraud, security, or
            technical issues); Respond to requests or comply with any legal
            proceedings, subpoenas, or government demands; Or protect the
            rights, property, or personal safety of group users, partners, or
            the public;
          </li>
          <li>
            3.2If the team has undergone a business transition, such as a merger
            or acquisition of another company, merger, change of control,
            restructuring or sale of all or part of its assets, your information
            will be included in the transferred assets.
          </li>
        </ul>
        <h3>4.Third party content</h3>
        <p>
          Some websites and services may include and/or enable you to add
          content to them. The form of content can be buttons, widgets, links to
          third-party advertisements, websites, products and services (including
          search services), as well as other means provided to you by third
          parties (collectively referred to as "third-party content and
          services"). The use of such third-party content and services is
          subject to their privacy policies. You should be aware that using
          third-party content and services may enable these third parties to
          access, collect, store, and/or share your information, including your
          PII and non personally identifiable information. For the sake of
          clarification, we do not review, approve, monitor, acknowledge,
          guarantee or make any representations regarding such third-party
          content and services, and the risk of your accessing any third-party
          content and services is your own responsibility. We do not assume any
          responsibility for your use or inability to use or access these
          third-party content and services in practice. You expressly exempt us
          from any liability arising from the use of such third-party content
          and services. We suggest that you read the terms, conditions, and
          privacy policy of any third-party content and services before using or
          accessing them.
        </p>
        <h3>5.Transfer data outside of your area</h3>
        <p>
          We work in international space and provide our website and services to
          users around the world. We and third-party organizations that provide
          automated data processing technology for websites and services may
          transfer cross border automated information from your country or
          region to other countries or regions around the world. According to
          legal requirements, anywhere you are located in your current location,
          you hereby expressly agree to transfer your data to a location outside
          of your current location after using the website and services. You can
          contact us at any time by phone on the official website below to
          withdraw your consent.
        </p>
        <h3>6.How will we retain your personal data</h3>
        <p>
          We usually retain your personal information until you fulfill the
          contract with us and comply with our legal obligations. If you no
          longer wish us to use the information we actually access and store,
          you can request us to erase your personal information and close your
          account. Specifically, facial data in this product is only used for
          recognition, extraction, and operation during functional use, and is
          not stored in any way. However, if information is required to comply
          with legal obligations (taxation, accounting, auditing) or to maintain
          security and data backup settings, prevent fraud or other malicious
          behavior, certain data may still be stored for a period of time.
        </p>
        <h3>7.Your data rights</h3>
        <p>
          You have the right to access, modify, correct or delete any personal
          data that we may collect. To exercise this right, please contact us
          through the website. If you are in the European Economic Area, you
          have the right (with a few exceptions)：
        </p>
        <ul>
          <li>
            （i）Requesting access and correction or deletion of your personal
            information；
          </li>
          <li>
            （ii）Obtaining processing restrictions or opposing the processing
            of your personal information；
          </li>
          <li>
            （iii）Please provide a copy of your personal information in digital
            format. You also have the right to file a complaint with EEA's local
            data protection agency regarding the processing of your personal
            information。
          </li>
        </ul>
        <h3>8.Change Privacy Policy</h3>
        <p>
          This privacy policy may be subject to change from time to time. Any
          changes will be published on the website interface. Your continued use
          of the website and services will be considered as acceptance of such
          updates.
        </p>
        <h3>9.Privacy issues</h3>
        <p>
          All other rights not declared in this agreement shall remain the
          property of our company. Our company reserves the final interpretation
          right of this agreement. If you have any other questions or
          suggestions, you can contact us through the official website.
        </p>
      </div>
      <!-- <el-button plain @click="ysVisible= false">关 闭</el-button> -->
    </el-dialog>
    <el-dialog :visible.sync="tkVisible" width="50%" center top="5vh">
      <div class="logo">
        <img src="../images/chip.png" alt="" />
        <div class="companyName">LI MAO INC</div>
      </div>
      
      <div class="content-title">
        Confidentiality Agreement
      </div>
      
      <div>
        <h3>YOU AGREE AS FOLLOWS:</h3>
        <h4>1. Where Confidential Information is disclosed, you shall:</h4>
        <ul>
          <li>
            (a) not use or allow the use of the Confidential Information for any purpose other than the Purpose stated
            herein, and
          </li>
          <li>
            <p>
              (b) not disclose or allow disclosure to others except in connection with the Purpose and then only to
              either:
            </p>
            <ul>
              <li>
                (i) your directors, employees, officers, agents, advisors (including solicitors and accountants),
                consultants and other representatives (collectively Representatives) and those Representatives of any
                affiliated entities under the same control of the same entity as controls you, and/or
              </li>
              <li>
                (ii) such other third party as LI MAO INC shall agree in writing from time to time,
              </li>
              <li>
                (Collectively the Permitted Recipients)
              </li>
            </ul>
          </li>
        </ul>
        <h4>2. You shall protect all Confidential Information received using a reasonable degree of care which shall be
          at
          least the same degree of care as you normally exercise to protect your own confidential information of like
          character and importance and which shall include (without limitation):</h4>
        <ul>LI MAO INC
          <li>
            <p>
              (a) only providing Confidential Information to Representatives whose duties require them to have
              Confidential Information and who are under an obligation to maintain the confidentiality of such
              information;
            </p>
          </li>
          <li>
            <p>
              (b) imposing on any third parties to whom a disclosure has been made a written obligation to treat the
              Confidential Information as confidential according to the terms of this Agreement (unless such recipients
              are already under a similar duty of confidentiality);
            </p>
          </li>
          <li>
            <p>
              (c) properly storing all written Confidential Information in your possession or under its control; and
            </p>
          </li>
          <li>
            <p>
              (d) not making unnecessary copies of written or electronic Confidential Information. </p>
          </li>

        </ul>
        <h4>
          3. Without the prior written consent of LI MAO INC, you shall not disclose other than to Permitted Recipients,
          and
          shall direct your Permitted Recipients not to, disclose to any person any Confidential Information or the fact
          that discussions or negotiations are taking place concerning a possible transaction between you and LI MAO INC
          or any of the terms, conditions, or other facts with respect to any such possible transaction, including the
          status thereof, unless the disclosure is required to comply with the laws in force in any applicable
          jurisdiction (including without limitation the rules, regulations, policies and procedures established by any
          securities commission, stock exchange or other regulatory body having jurisdiction over you or a Permitted
          Recipient or legal process or the order or rules of any court or regulatory body of competent jurisdiction).
          If you or any other Permitted Recipient is required to make disclosure as set forth above, you may proceed
          with such disclosure provided that you have, if allowed by the applicable laws, used all reasonable efforts
          to:
        </h4>
        <ul>
          <li>
            <p>(i) provide LI MAO INC with advance notice of the nature and extent of such disclosure so that LI MAO INC
              may, at its own expense, seek a protective order or other appropriate remedy to avoid or limit such
              disclosure; and </p>
          </li>
          <li>
            <p>(ii) consult with LI MAO INC as to the content and timing of such disclosure.</p>
          </li>
        </ul>
        <h4>4. The obligations imposed by this Agreement shall not apply to any information that:</h4>
        <ul>
          <li>
            <p>(a) is lawfully known by you or is already in your possession at the time of disclosure, or</p>
          </li>
          <li>
            <p>(b) is lawfully received by you from a third party who does not have an obligation of confidentiality to
              LI MAO INC,</p>
          </li>
          <li>
            <p>In the case of paragraph (b) above, the removal of the restriction shall be effective only from and after
              the occurrence of the applicable event.</p>
          </li>
        </ul>
        <h4>5.You shall notify LI MAO INC immediately upon discovery of any unauthorised use or disclosure of any
          Confidential
          Information or any breach of this Agreement by you or any Permitted Recipient and shall provide such
          reasonable assistance as is necessary to help LI MAO INC regain possession of the Confidential Information and
          prevent its further unauthorised use.</h4>
        <h4>
          6.This Agreement does not, nor does the act of disclosure imply or confer upon you any right, license,
          interest or title to the Confidential Information provided by LI MAO INC. All Confidential Information
          provided
          by LI MAO INC under this Agreement together with all materials provided shall remain the property of LI MAO
          INC or
          its licensors. Upon the written request of LI MAO INC, you shall either collect and surrender, or confirm the
          destruction or non-recoverable data erasure of all Confidential Information and all copies thereof, regardless
          of form, including information based on or incorporating any Confidential Information and any such destruction
          shall be certified in writing to LI MAO INC by an authorised officer of you supervising such destruction.
        </h4>
        <h4>7.Neither the execution and delivery of this Agreement nor the disclosure of any Confidential Information
          hereunder shall be construed as granting by implication, estoppel or otherwise, any right in or license under
          any present or future invention, trade secret, trademark copyright, or patent now or hereafter owned or
          controlled by any of the Parties hereto.</h4>
        <h4>
          8.LI MAO INC is not obligated to furnish any Confidential Information and/or to provide any services to you.
        </h4>
        <h4>
          9.The opportunity to receive Confidential Information under this Agreement may be terminated at any time upon
          written notice by LI MAO INC to you. Such termination shall not affect any obligation imposed by this
          Agreement
          with respect to any Confidential Information received prior to such termination.
        </h4>
        <h4>
          10.You acknowledge that the unauthorised disclosure or use of the Confidential Information could cause
          irreparable damage to LI MAO INC and agree to compensate LI MAO INC for its losses, including but not limited
          to
          loss of goodwill or reputation, loss of profit, loss of income, loss of business interruption, loss of
          business opportunities, loss of anticipated profit and other indirect or consequential losses, as well as
          administrative penalties, fines, legal fees, notarial fees, etc.. Without prejudice to any other rights or
          remedies which LI MAO INC may have, you acknowledge and agree that damages may not be an adequate remedy for
          any
          breach by you of the provisions of this Agreement and LI MAO INC shall be entitled to the remedies of
          injunction, specific performance and other equitable relief for any threatened or actual breach of any such
          provision by you and no proof of special damages shall be necessary for the enforcement by LI MAO INC of its
          rights under this Agreement. You shall notify LI MAO INC immediately upon becoming aware of any such breach or
          threatened breach. LI MAO INC shall be entitled to recover from you in such litigation all of its costs,
          expenses and reasonable attorney fees paid or incurred in connection therewith.
        </h4>
        <h4>11.LI MAO INC may amend this Agreement from time to time by giving prior notice of a period of not less than
          10 days. LI MAO INC will note the date that amendments take effect at the top of this Agreement. LI MAO INC
          may
          give notice through its website or by other means as it considers appropriate.
          If
          you do not accept any amendment, you should stop using any of the LI MAO INC Services before the effective
          date
          of the amendment. If you continue to use any LI MAO INC Services after the effective date, you are deemed to
          have accepted and shall be bound by such amendment.</h4>
        <h4>12.This Agreement (or any discussion or disclosures under or in connection with this Agreement) shall not be
          deemed as a commitment to any business relationship, contract or future dealing between you and LI MAO INC.
        </h4>
        <h4>13.This Agreement (or any discussion or disclosures under or in connection with this Agreement) shall not
          prevent LI MAO INC from conducting similar discussion with, or providing any similar services to, any other
          parties.</h4>
        <h4>14.This Agreement shall be governed by, and construed in accordance with, the laws of Hong Kong. Any dispute
          arising from or in connection with this Agreement shall be submitted to Shanghai International Economic and
          Trade Arbitration Commission for arbitration.</h4>
        <h4>15.This Agreement is between you and LI MAO INC. Unless other provided under this Agreement, no other person
          shall have any rights to enforce any of the terms of this Agreement. If any court or relevant authority finds
          that part of the terms of this Agreement are illegal, the rest will continue in full force and effect. Even if
          LI MAO INC delays enforcing this Agreement, it may still take steps against you and/or your Permitted
          Recipients
          at a later date.</h4>
      </div>
      <!-- <el-button plain @click="tkVisible= false">关 闭</el-button> -->
    </el-dialog>
  </div>
</template>

<script>
import ctdata from "../plugins/countryData.json";

export default {
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("register.des8")));
      } else if (value !== this.form_reg.password) {
        callback(new Error(this.$t("register.des8")));
      } else {
        callback();
      }
    };
    return {
      gjcodes: "+86 中国",
      gjda: ctdata,
      centerDialogVisible: false,
      ysVisible: false,
      tkVisible: false,
      codeSrc: "",
      agr: false,
      Rpassword: "",
      emailType: '3', // 邮箱类型
      form_login:{},
      form_reg: {
        phonenumber: "",
        code: "",
        uuid: "",
        password: "",
        inviteCode: "yzqklZ9y",
        realcard: "",
        realname: "",
      }, // 表单验证规则
      regFormRules: {
        // 验证用户名
        phonenumber: [
          {
            required: true,
            message: this.$t("register.userrule"),
            tigger: "blur",
          },
          {
            min: 11,
            max: 11,
            message: this.$t("register.userLengthrule"),
            trigger: "blur",
          },
        ],
        // 验证密码
        password: [
          {
            required: true,
            message: this.$t("register.pswrule"),
            tigger: "blur",
          },
          {
            min: 5,
            max: 15,
            message: this.$t("register.pswLengthrule"),
            trigger: "blur",
          },
          {
            pattern: "^[0-9a-zA-Z_]{1,}$",
            message: this.$t("register.pswabcrule"),
          },
        ],
        Rpassword: [
          {
            required: true,
            message: this.$t("register.pswAgainrule"),
            tigger: "blur",
          },
          {
            min: 5,
            max: 15,
            message: this.$t("register.pswLengthrule"),
            trigger: "blur",
          },
          {
            validator: validatePass2,
            trigger: "blur",
          },
          {
            pattern: "^[0-9a-zA-Z_]{1,}$",
            message: this.$t("register.pswabcrule"),
          },
        ],
        inviteCode: [
          {
            required: true,
            message: this.$t("register.codeurule"),
            tigger: "blur",
          },
        ],
        email: [
          { required: true, message: this.$t("register.des9"), trigger: 'blur' },
          { type: 'email', message: this.$t("register.des9"), trigger: ['blur', 'change'] }
        ],
        realname: [
          {
            required: true,
            message: this.$t("register.namerule"),
            tigger: "blur",
          },
          {
            validator: this.validateChinese, // 使用自定义验证函数
            trigger: 'blur'
          }
        ],
        realcard: [
          {
            required: true,
            message: this.$t("register.idrule"),
            tigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    loadCSS(value) {
      this.removeCSS(value)
      var cssLink = document.createElement("link");
      cssLink.rel = "stylesheet";
      cssLink.type = "text/css";
      // cssLink.href = `/register/theme/${value}/index.css`;
      cssLink.href = this.$csslink + `/theme/${value}/index.css`;
      cssLink.id = `theme-css-${value}`; // 设置唯一 ID
      document.getElementsByTagName("head")[0].appendChild(cssLink);
    },
    removeCSS(themeName) {
      var link = document.getElementById(`theme-css-${themeName}`);
      if (link) {
        link.parentNode.removeChild(link);
      }
    },
    validateChinese(rule, value, callback) {
      const chineseRegex = /^[\u4e00-\u9fa5]+$/;
      if (!value) {
        callback(new Error(this.$t("register.namerule"))); // 提示用户输入不能为空
      } else if (!chineseRegex.test(value)) {
        callback(new Error(this.$t("register.chineserule"))); // 提示用户输入必须是中文
      } else {
        callback(); // 验证通过
      }
    },
    register() {
      if (this.agr == false) {
        this.$message({
          message: "请选中用户协议!",
          type: "warning",
        });
        return;
      }
      let phoneRegex = /^(1[3-9])\d{9}$/;
      let passwordRegex = /^.{5,15}$/;
      let nameRegex = /^[\u4e00-\u9fa5]{2,4}$/;
      let idcardRegex = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
      let regs = {
        users: this.form_reg.phonenumber,
        // email: this.form_reg.email,
        pass: this.form_reg.password,
        rpass: this.form_reg.Rpassword,
        invitation: this.form_reg.inviteCode,
        code: this.form_reg.code.toLowerCase(),
        codeid: this.uuid,
        realcard: this.form_reg.realcard,
        realname: this.form_reg.realname,
      };
      if(!this.form_reg.phonenumber || (phoneRegex.test(this.form_reg.phonenumber) == false)){
        this.$message.error("请输入正确的手机号码")
        return
      }
      if(!this.form_reg.password || (passwordRegex.test(this.form_reg.password) == false)){
        this.$message.error("请输入正确的密码格式(5-15字母数字)")
        return
      }
      if(!this.form_reg.Rpassword){
        this.$message.error("请确认密码")
        return
      }
      if(this.form_reg.Rpassword != this.form_reg.password){
        this.$message.error("两次密码不一致")
        return
      }
      if(!this.form_reg.inviteCode){
        this.$message.error("请输入邀请码")
        return
      }
      if(!this.form_reg.realname || (nameRegex.test(this.form_reg.realname) == false)){
        this.$message.error("请输入真实姓名")
        return
      }
      if(!this.form_reg.realcard || (idcardRegex.test(this.form_reg.realcard) == false)){
        this.$message.error("请输入正确格式身份证号")
        return
      }
      
      if(!this.form_reg.code){
        this.$message.error("请输入验证码")
        return
      }

      regs = this.$lmjm(regs);
        const result = this.$http.post("/api/v1/register", regs);
        result.then(
          (res) => {
            if (res.data.code === 200) {
              // 获得成功响应返回的数据
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.$cookie.set("user", this.form_reg.phonenumber);
              this.$router.push("/login");
            } else {
              this.$message.error(res.data.msg);
              this.getCodeUrl();
            }
          },
          (error) => {
            this.$message.error("注册失败，请重新注册!");
            this.getCodeUrl();
          }
        );
    },
    go_login() {
      this.$router.push("/login");
    },
    getCodeUrl() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.get(
        "/api/v1/captcha?" +
        "key=" +
        params.key +
        "&noncestr=" +
        params.noncestr +
        "&sign=" +
        params.sign +
        "&timestmap=" +
        params.timestmap
      );
      result.then(
        (res) => {
          if (res.status === 200) {
            // 获得成功响应返回的数据
            this.codeSrc = res.data.data.codeurl;
            this.uuid = res.data.data.codeid;
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    getct() {
      const result = this.$http.get("../../plugins/countryData");
      result.then(
        (res) => {
          console.log(res);
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 判断ip
    getChinaIp() {
      // 判断用户IP
      let params = {};
      params = this.$lmjm(params)
      const result = this.$http.post('/api/v1/getipaddress', params, {
        headers: {
        }
      })
      result.then((res) => {
        if (res.data.data.in_china == 1 && res.data.data.iplimit == 1) {
          window.open('/error.html', '_self')
          // router.push('/login')
        } else {
          this.$router.push("/register");
        }
      }, (error) => {
        console.log('错误:' + error)
      })
    },
  },
  mounted() {
    localStorage.setItem('theme', 'light')
    document.documentElement.setAttribute('theme', 'light')
    this.loadCSS('light')
  },
  created() {
    // if(this.form_reg.inviteCode !="yzqklZ9y"){
    //   const geturl = window.location.href
    //   const getqyinfo = geturl.split('?')[1]
    //   const getqys = new URLSearchParams('?' + getqyinfo)
    //   const getqycode = getqys.get('codes')
    //   this.form_reg.inviteCode = getqycode
    // }
    this.getChinaIp()
    const geturl = window.location.href;
    const getqyinfo = geturl.split("?")[1];
    const getqys = new URLSearchParams("?" + getqyinfo);
    const getqycode = getqys.get("codes");
    if (getqycode != null) {
      this.form_reg.inviteCode = getqycode;
    }

    this.getCodeUrl();
  },
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  min-height: 100%;
  height: 100%;
  background-color: #fff;
  flex-direction: column;
  box-sizing: border-box;
}

.db-RegisterAndLoginBackground-background {
  z-index: 0;
  scrollbar-gutter: stable;
  width: 100vw;
  position: fixed;
  top: -250px;
  bottom: 0;
  left: 0;
  overflow: hidden;
  transform: skewY(-12deg);
}

.db-RegisterAndLoginBackground-grayBackground {
  transform-origin: 0%;
  height: 1698px;
  background-color: rgb(247, 250, 252);
  position: absolute;
  top: -1000px;
  left: 0;
  right: 0;
}

.db-RegisterAndLoginBackground-verticalLine--first {
  left: calc(50% - 540px);
}

.db-RegisterAndLoginBackground-verticalLine--fifth,
.db-RegisterAndLoginBackground-verticalLine--first {
  background: #eceef4;
}

.db-RegisterAndLoginBackground-verticalLine {
  width: 1px;
  background-image: linear-gradient(#eceef4 49%, #eceef400 50%);
  background-size: 1px 8px;
  position: absolute;
  top: -100vh;
  bottom: -100vh;
  right: auto;
}

.db-RegisterAndLoginBackground-verticalLine--second {
  left: calc(50% - 270px);
}

.db-RegisterAndLoginBackground-verticalLine--third {
  left: 50%;
}

.db-RegisterAndLoginBackground-verticalLine--fourth {
  left: calc(50% + 269px);
}

.db-RegisterAndLoginBackground-verticalLine--fifth {
  left: calc(50% + 540px);
}

.db-RegisterAndLoginBackground-verticalLine--fifth,
.db-RegisterAndLoginBackground-verticalLine--first {
  background: #eceef4;
}

.db-RegisterAndLoginBackground-firstLeftStripe {
  height: 40px;
  top: 668px;
  left: calc(50% - 700px);
  right: calc(50% + 540px);
}

.db-RegisterAndLoginBackground-firstAndSecondLeftStripeBlend,
.db-RegisterAndLoginBackground-firstLeftStripe,
.db-RegisterAndLoginBackground-firstRightStripe,
.db-RegisterAndLoginBackground-secondLeftStripe {
  position: absolute;
}

.db-RegisterAndLoginBackground-secondLeftStripe {
  height: 40px;
  top: 698px;
  left: -10px;
  right: calc(50% + 405px);
}

.db-RegisterAndLoginBackground-firstAndSecondLeftStripeBlend {
  height: 10px;
  top: 698px;
  left: calc(50% - 700px);
  right: calc(50% + 540px);
}

.db-RegisterAndLoginBackground-firstRightStripe {
  height: 40px;
  top: 658px;
  left: calc(50% + 430px);
  right: -10px;
}

.Box-root {
  box-sizing: border-box;
}

.db-RegisterAndLoginLayout-logo {
  padding-bottom: 40px;
  padding-left: 20px;
  font-size: 20px;
  font-weight: 900;
}

.Icon-color--gray800 {
  color: rgb(42, 47, 69);
}

.db-RegisterAndLoginLayout-contentWrapper {
  width: 1080px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.db-RegisterAndLoginLayout-formContainer {
  padding-top: 3%;
  width: 540px;
  min-width: 540px;
  display: flex;
  flex-direction: column;
}

.db-RegisterAndLoginLayout-form {
  position: relative;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 15px 35px 0 rgba(60, 66, 87, 0.08),
    0 5px 15px 0 rgba(0, 0, 0, 0.12);
  transform: translate3d(0px, 0px, 0);
  transform-origin: 0% 0%;
  width: 540px;
  min-width: 540px;
  height: 850px;
  will-change: transform, width, height;
  box-sizing: border-box;
  padding: 16px 48px;
}

.db-RegisterAndLoginLayout-form-row1{
  margin-top: 15px;
  margin-left: 20px;
  font-family: sohne-var, sohne-woff, Helvetica Neue, Arial, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -.2px;
  color: rgb(60,66,87);
}

.db-RegisterAndLoginLayout-form-row2{
  margin-top: 15px;
  margin-left: 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -.2px;
  color: rgb(60,66,87);
}

.db-RegisterAndLoginLayout-form-row3{
  margin-top: 15px;
  margin-left: 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -.2px;
  color: rgb(60,66,87);
}

.db-RegisterAndLoginLayout-form-row2-label{
  display: flex;
  justify-content: space-between;
}
.db-RegisterAndLoginLayout-form-row3-code{
  display: flex;
}

.codeImg {
  margin-left: 5%;
  margin-top: 12px;
  width: 50%;
  height: 40px;
  border-radius: 5px;
}

.db-RegisterAndLoginLayout-form-row5{
  margin-top: 20px;
  margin-left: 20px;
}

.db-RegisterAndLoginLayout-form-row4{
  margin-top: 8px;
  margin-left: 20px;
}
.db-RegisterAndLoginLayout-form-row4-btn{
  cursor: pointer;
  // margin-top: 8px;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 800;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .2s ease-out;
  background-color: rgb(99, 91, 255);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(69, 56, 255, 0.8) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
}

.db-RegisterAndLoginLayout-form-row4-btn:hover{
  transform: translateY(-3px);
}

.db-RegisterAndLoginLayout-form-row4-btn:active{
  transform: translateY(3px);
}

.martop--32{
  // margin-top: 32px;
}

.db-RegisterAndLoginLayout-foot{
  position: absolute;
  bottom: 5px;
  left: 5px;
  width: 98%;
  height: 50px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background: #f6f9fc;
  
}
.btn_foot{
  cursor: pointer;
  font-weight: 600;
  color: #635bff;
  transition: all .1s linear;
}
.btn_foot:hover{
  color: #000;
}
</style>
