<template>
  <el-main>
    <div class="title_tab">{{ name }}</div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <div class="user_box">
            <!-- 充值渠道 -->
            <el-select v-model="czVal" placeholder="审核状态" clearable
              style="width: 8%; margin-right: 10px; margin-left: 10px">
              <el-option v-for="item in selectCz" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-button style="margin-bottom: 20px; margin-left: 10px" icon="el-icon-search" @click="getDataList()">查
              询</el-button>
            <el-button v-no-more-click icon="el-icon-refresh" @click="getDataList('rf')">刷 新
            </el-button>
            <br />
            <br />
            <!-- 公告列表 -->
            <el-table :data="DataList" style="width: 100%" size="small" height="660" max-height="800">
              <!-- id -->
              <el-table-column prop="id" align="center" label="id" width="120">
              </el-table-column>
              <!-- 真实姓名 -->
              <el-table-column prop="realcard" align="center" label="姓名" width="120" :show-overflow-tooltip="true">
              </el-table-column>
              <!-- 真实姓名 -->
              <el-table-column prop="realname" align="center" label="身份证" width="200" :show-overflow-tooltip="true">
              </el-table-column>
              <!-- 企业类型 -->
              <el-table-column prop="company_type" align="center" label="企业类型" width="150"
                :show-overflow-tooltip="true">
              </el-table-column>
              <!-- 企业名 -->
              <el-table-column prop="company_name" align="center" label="企业名" width="200" :show-overflow-tooltip="true">
              </el-table-column>
              <!-- 企业注册证书 -->
              <el-table-column prop="image" align="center" label="企业上传文件" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <div
                    v-if="scope.row.company_type == '中国企业' || scope.row.company_type == 'Chinese enterprise' || scope.row.company_type == '中國企業'">
                    <el-image style="
                        width: 50px;
                        height: 50px;
                        border: 1px solid rgba(0, 0, 0, 0.2);
                        border-radius: 4px;
                      " :src="scope.row.image[0].url" :preview-src-list="[scope.row.image[0].url]">
                    </el-image>
                  </div>
                  <div
                    v-if="scope.row.company_type == '香港企业' || scope.row.company_type == 'Hong Kong enterprises' || scope.row.company_type == '香港企業'">
                    <img style="
                        width: 50px;
                        height: 50px;
                        border: 1px solid rgba(0, 0, 0, 0.2);
                        border-radius: 4px;
                      " src="@/images/pdf_file.png" @click="downPdf(scope.row.image)" alt="" />
                  </div>
                </template>
              </el-table-column>
              <!-- 时间 -->
              <el-table-column prop="createtime" align="center" label="申请时间" width="200" :show-overflow-tooltip="true">
              </el-table-column>
              <!-- 公告类型 -->
              <el-table-column prop="status" align="center" label="审核状态" width="120">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.status === 1" type="warning">
                    申请中
                  </el-tag>
                  <el-tag v-else-if="scope.row.status === 2" type="success">
                    通过
                  </el-tag>
                  <div v-else-if="scope.row.status === 3">
                    <el-tag type="danger"> 驳回 </el-tag>

                    <el-tooltip class="item" effect="dark" :content="scope.row.refuse_msg" placement="top">
                      <i style="margin-left: 8px" class="el-icon-warning"></i>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <!-- 操作 -->
              <el-table-column label="操作" align="center" width="180">
                <template slot-scope="scope">
                  <el-button :disabled="scope.row.status != 1" type="primary"
                    @click="editNotice(scope.row)">审核</el-button>
                  <!-- <el-button type="danger" @click="deleteNotice(scope.row)">删除</el-button> -->
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination align="center" @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="noticeTotal">
            </el-pagination>
          </div>
          <!-- 审核认证 -->
          <el-dialog custom-class="ls" title="审核认证" :visible.sync="newCardsdialogVisible" width="60%">
            <el-form label-position="top" label-width="80px" :model="form">
              <!-- 标题 -->
              <el-form-item label="审核">
                <el-switch v-model="form.status" active-color="#13ce66" inactive-color="#ff4949" active-text="通过"
                  inactive-text="驳回">
                </el-switch>
              </el-form-item>
              <!-- 内容 -->
              <el-form-item label="拒绝原因">
                <el-input type="textarea" v-model="form.refuse_msg" :autosize="{ minRows: 6, maxRows: 50 }"></el-input>
              </el-form-item>
            </el-form>
            <div class="form_foot">
              <el-button @click="addNotice(), (newCardsdialogVisible = false)" v-no-more-click>确
                定</el-button>
              <el-button @click="newCardsdialogVisible = false">取 消</el-button>
            </div>
          </el-dialog>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      name: "企业审核",
      noticeTotal: 0,
      form: {
        status: false,
      },
      selectId: "",
      DataList: [],
      czVal: "", // 查询参数
      selectCz: [
        {
          value: 1,
          label: "申请中",
        },
        {
          value: 2,
          label: "通过",
        },
        {
          value: 3,
          label: "驳回",
        },
      ],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页数据显示 条数
      newCardsdialogVisible: false, // 审核窗口
    };
  },
  methods: {
    async downPdf(fileList) {
      if (fileList.length > 0) {
        // Function to create and click a link
        const downloadFile = (file) => {
          return new Promise((resolve) => {
            const link = document.createElement("a");
            link.href = file.url; // 文件的 URL
            link.download = file.name; // 下载文件的名称
            // 将 <a> 元素添加到文档中
            document.body.appendChild(link);
            // 触发点击事件
            link.click();
            // 移除 <a> 元素
            document.body.removeChild(link);
            setTimeout(resolve, 1000);
          });
        };

        for (const file of fileList) {
          await downloadFile(file);
        }
      }
    },
    // 页数
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    // 审核
    editNotice(item) {
      this.form = {
        status: false,
      };
      this.selectId = item.id;
      this.newCardsdialogVisible = true;
    },
    // 审核确认弹窗
    addNotice() {
      console.log(
        "%c [ this.form ]-123",
        "font-size:13px; background:pink; color:#bf2c9f;",
        this.form
      );
      let params = {
        id: this.selectId,
        status: this.form.status ? 2 : 3,
        refuse_msg: this.form.refuse_msg,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/auditCompany", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then((res) => {
        this.getDataList();
      });
    },
    // 获取数据
    getDataList(flag) {
      if (flag && flag == "rf") {
        this.currentPage = 1;
        this.pageSize = 10;
        this.czVal = "";
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        status: this.czVal,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getCompanyList", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.DataList = res.data.data.data;
          this.noticeTotal = res.data.data.total;
          for (let i = 0; i < this.DataList.length; i++) {
            this.DataList[i].createtime = this.getTime(
              res.data.data.data[i].createtime
            );

            this.DataList[i].image = JSON.parse(this.DataList[i].image);
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 格式化时间
    getTime(/** timestamp=0 **/) {
      const ts = arguments[0] || 0;
      let t, y, m, d, h, i, s;
      t = ts ? new Date(ts * 1000) : new Date();
      y = t.getFullYear();
      m = t.getMonth() + 1;
      d = t.getDate();
      h = t.getHours();
      i = t.getMinutes();
      s = t.getSeconds();
      // 可依据须要在这里定义时间格式
      return (
        y +
        "-" +
        (m < 10 ? "0" + m : m) +
        "-" +
        (d < 10 ? "0" + d : d) +
        " " +
        (h < 10 ? "0" + h : h) +
        ":" +
        (i < 10 ? "0" + i : i) +
        ":" +
        (s < 10 ? "0" + s : s)
      );
    },
  },
  created() {
    this.getDataList();
  },
  mounted() { },
};
</script>

<style scoped>
.form_foot {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}
</style>
