<template>
  <el-main>
    <div class="title_tab">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>卡片管理</el-breadcrumb-item>
        <el-breadcrumb-item>{{ name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <div class="user_box">
            <el-row :gutter="20">
              <el-form :model="zd_form">
                <div class="zd_box">
                  <div style="font-size: 22px; margin-left: 42px">
                    {{ name }}
                  </div>
                  <!-- 矢量插画 -->
                  <img class="img_svg" src="@/images/svg/billForm.svg" alt="" srcset="">
                  <br />
                  <div class="billInfo_box">
                    <!-- 分类 -->
                    <div class="billInfo_box_title">个人信息</div>
                    <!-- 表单项 -->
                    <div class="billInfo_box_form">
                      <div class="item" style="width:15%">
                        <div class="filed">姓<span style="color: red; font-size: 14px"> * </span></div>
                        <el-input v-model="zd_form.last_name" placeholder="Jack" autocomplete="off"
                          style="width:100%"></el-input>
                      </div>
                      <div class="item" style="width:15%">
                        <div class="filed">名<span style="color: red; font-size: 14px"> * </span></div>
                        <el-input v-model="zd_form.first_name" placeholder="Rio" autocomplete="off"
                          style="width:100%"></el-input>
                      </div>
                      <div class="item" style="width:15%">
                        <div class="filed">出生日期<span style="color: red; font-size: 14px"> * </span></div>
                        <el-input v-model="zd_form.births" placeholder="2023-01-01" autocomplete="off"
                          style="width:100%"></el-input>
                      </div>

                    </div>
                    <!-- 分类 -->
                    <div class="billInfo_box_title">联系方式</div>
                    <!-- 表单项 -->
                    <div class="billInfo_box_form">
                      <div class="item" style="width:15%">
                        <div class="filed">邮箱<span style="color: red; font-size: 14px"> * </span></div>
                        <el-input v-model="zd_form.emails" placeholder="explame@gmail.com" autocomplete="off"
                          style="width:100%"></el-input>
                      </div>
                      <div class="item" style="width:15%">
                        <div class="filed">电话号码<span style="color: red; font-size: 14px"> * </span></div>
                        <el-input v-model="zd_form.mobile" placeholder="4052941287" autocomplete="off"
                          style="width:100%"></el-input>
                      </div>
                    </div>
                    <!-- 分类 -->
                    <div class="billInfo_box_title">所在地</div>
                    <!-- 表单项 -->
                    <div class="billInfo_box_form">
                      <div class="item" style="width:15%">
                        <div class="filed">国家码<span style="color: red; font-size: 14px"> * </span></div>
                        <el-input v-model="zd_form.nationality_country_code" placeholder="US" autocomplete="off"
                          style="width:100%"></el-input>
                      </div>
                      <div class="item" style="width:15%">
                        <div class="filed">区号<span style="color: red; font-size: 14px"> * </span></div>
                        <el-input v-model="zd_form.mobile_prefix" placeholder="001" autocomplete="off"
                          style="width:100%"></el-input>
                      </div>
                      <div class="item" style="width:15%">
                        <div class="filed">邮编</div>
                        <el-input v-model="zd_form.residential_postal_code" placeholder="90013" autocomplete="off"
                          style="width:100%"></el-input>
                      </div>
                      <div class="item" style="width:15%">
                        <div class="filed">省(洲)</div>
                        <el-input v-model="zd_form.residential_state" placeholder="CA" autocomplete="off"
                          style="width:100%"></el-input>
                      </div>
                      <div class="item" style="width:15%">
                        <div class="filed">城市</div>
                        <el-input v-model="zd_form.residential_city" placeholder="Los Angeles" autocomplete="off"
                          style="width:100%"></el-input>
                      </div>
                    </div>
                    <!-- 分类 -->
                    <div class="billInfo_box_title">住址</div>
                    <!-- 表单项 -->
                    <div class="billInfo_box_form">
                      <div class="item" style="width:100%">
                        <div class="filed">地址</div>
                        <el-input v-model="zd_form.residential_address" placeholder="5 W 5th Room 34027"
                          autocomplete="off" style="width:100%"></el-input>
                      </div>
                    </div>
                  </div>
                  <!-- <el-descriptions direction="horizontal" :column="4" border>
                    <el-descriptions-item label="姓">
                      <el-input
                        v-model="zd_form.last_name"
                        placeholder="Jack"
                        autocomplete="off"
                      ></el-input>
                      <span style="color: red; font-size: 22px"> * </span>
                    </el-descriptions-item>
                    <el-descriptions-item label="名">
                      <el-input
                        v-model="zd_form.first_name"
                        placeholder="Rio"
                        autocomplete="off"
                      ></el-input>
                      <span style="color: red; font-size: 22px"> * </span>
                    </el-descriptions-item>
                    <el-descriptions-item label="出生日期" :span="2">
                      <el-input
                        v-model="zd_form.births"
                        placeholder="2023-01-01"
                        autocomplete="off"
                      ></el-input>
                      <span style="color: red; font-size: 22px"> * </span>
                    </el-descriptions-item>
                    <el-descriptions-item label="邮箱">
                      <el-input
                        v-model="zd_form.emails"
                        placeholder="explame@gmail.com"
                        autocomplete="off"
                      ></el-input>
                      <span style="color: red; font-size: 22px"> * </span>
                    </el-descriptions-item>
                    <el-descriptions-item label="区号">
                      <el-input
                        v-model="zd_form.mobile_prefix"
                        placeholder="001"
                        autocomplete="off"
                      ></el-input>
                      <span style="color: red; font-size: 22px"> * </span>
                    </el-descriptions-item>
                    <el-descriptions-item label="电话号码" :span="2">
                      <el-input
                        v-model="zd_form.mobile"
                        placeholder="4052941287"
                        autocomplete="off"
                      ></el-input>
                      <span style="color: red; font-size: 22px"> * </span>
                    </el-descriptions-item>
                    <el-descriptions-item label="国家码">
                      <el-input
                        v-model="zd_form.nationality_country_code"
                        placeholder="US"
                        autocomplete="off"
                      ></el-input>
                      <span style="color: red; font-size: 22px"> * </span>
                    </el-descriptions-item>
                    <el-descriptions-item label="邮编" :span="3">
                      <el-input
                        v-model="zd_form.residential_postal_code"
                        placeholder="90013"
                        autocomplete="off"
                      ></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="省(州)">
                      <el-input
                        v-model="zd_form.residential_state"
                        placeholder="CA"
                        autocomplete="off"
                      ></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="城市" :span="3">
                      <el-input
                        v-model="zd_form.residential_city"
                        placeholder="Los Angeles"
                        autocomplete="off"
                      ></el-input>
                    </el-descriptions-item>
                    <el-descriptions-item label="地址">
                      <el-input
                        v-model="zd_form.residential_address"
                        placeholder="5 W 5th Room 34027"
                        autocomplete="off"
                      >
                      </el-input>
                    </el-descriptions-item>
                  </el-descriptions> -->
                </div>
                <div class="footGourp">
                  <!-- <el-button
                    type="primary"
                    @click="setBill()"
                    icon="el-icon-edit"
                    >确 定</el-button
                  > -->
                  <div class="setBillBtn" @click="setBill()" v-no-more-click>确 定</div>
                </div>
              </el-form>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import BigNumber from "bignumber.js";
import { Loading } from "element-ui";
import { bignumber, number } from "mathjs";
const lodash = require("lodash");
export default {
  data() {
    return {
      card_nums: "",
      userRecodTotal: 0,
      userRecodcurrentPage: 1, // 用户交易记录当前页码
      userRecodpageSize: 30, // 用户交易记录每页数据显示 条数
      isTs: false,
      name: "账单地址",
      dialogVisible: false,
      formLabelWidth: "120px",
      zd_form: {
        births: "",
        emails: "",
        first_name: "",
        last_name: "",
        mobile: "",
        mobile_prefix: "",
        nationality_country_code: "",
        residential_address: "",
        residential_city: "",
        residential_postal_code: "",
        residential_state: "",
      },
      cardTypes: "",
      fullscreenLoading: false,
      diolog_zd: false,
      lods: "",
      nums: 1,
      rechargeAmount: 0.0,
      waytype: "",
      types: "",
      successCardNums: 0,
      cardData: [],
      form_queryCards: {
        spend: "",
      },
      labelPosition: "right",
      form_queryCardsRules: {},
      cny: "0",
      usd: "0",
      resetsinter: null,
      reconnectnum: 0,
    };
  },
  methods: {
    // 回显账单信息
    getBill() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getBill", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            this.zd_form.births = res.data.data.births;
            this.zd_form.emails = res.data.data.emails;
            this.zd_form.first_name = res.data.data.first_name;
            this.zd_form.last_name = res.data.data.last_name;
            this.zd_form.mobile = res.data.data.mobile;
            this.zd_form.mobile_prefix = res.data.data.mobile_prefix;
            this.zd_form.nationality_country_code =
              res.data.data.nationality_country_code;
            this.zd_form.residential_address =
              res.data.data.residential_address;
            this.zd_form.residential_city = res.data.data.residential_city;
            this.zd_form.residential_country_code =
              res.data.data.residential_country_code;
            this.zd_form.residential_postal_code =
              res.data.data.residential_postal_code;
            this.zd_form.residential_state = res.data.data.residential_state;
            this.diolog_zd = true;
          } else {
            this.$message({
              message: "获取账单信息失败!",
              type: "error",
            });
            this.diolog_zd = true;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 设置账单信息
    setBill() {
      let params = {
        births: this.zd_form.births,
        emails: this.zd_form.emails,
        first_name: this.zd_form.first_name,
        last_name: this.zd_form.last_name,
        mobile: this.zd_form.mobile,
        mobile_prefix: this.zd_form.mobile_prefix,
        nationality_country_code: this.zd_form.nationality_country_code,
        residential_address: this.zd_form.residential_address,
        residential_city: this.zd_form.residential_city,
        residential_country_code: this.zd_form.nationality_country_code,
        residential_postal_code: this.zd_form.residential_postal_code,
        residential_state: this.zd_form.residential_state,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/setBill", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            console.log(res);
            this.$message({
              message: "设置账单信息成功!",
              type: "success",
            });
            this.diolog_zd = false;
            this.getBill();
          } else {
            console.log(res);
            this.$message({
              message: "设置账单信息失败!" + res.data.msg,
              type: "error",
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    initWebSocket() {
      // 初始化weosocket
      const token = window.sessionStorage.getItem("token");
      const wsuri = "wss://" + this.$domain + "/wss?token=" + token;
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen() {
      // 连接建立之后执行send方法发送数据
      const actions = {
        type: "login",
        client_name: "yy",
        room_id: "1",
      };
      this.websocketsend(JSON.stringify(actions));
      this.resetsinter = setInterval(this.heartbeat, 30000);
    },
    websocketsend(Data) {
      // 数据发送
      this.websock.send(Data);
    },
    websocketonerror(e) {
      // 连接建立失败重连
      this.errorhandle(e);
    },
    websocketclose(e) {
      // 关闭
      this.errorhandle(e);
    },
    errorhandle(e) {
      console.log("连接异常，尝试重连");
      // 连接建立失败，或者连接被关闭，全都到这儿来
      this.reconnectnum++;
      // 允许重连3次，不然这里会无限重连，给服务器带来非常大的压力。
      if (this.reconnectnum < 3) {
        if (this.resetsinter) {
          clearInterval(this.resetsinter);
          this.resetsinter = null;
          this.websock.close();
        }
        this.initWebSocket();
        console.log("断开连接", e);
      } else {
        clearInterval(this.resetsinter);
        this.resetsinter = null;
        // 关闭重连
        console.log(
          "超过3次了，关闭前端websocket所有服务，只能下次刷新后重新尝试连接,因为服务端close连接而导致的前端无限重连"
        );
        // this.websock.close()
      }
    },
    refreshcon() {
      console.log("后端如果向前端发送了websocket");
      // 后端如果向前端发送了websocket，则重新开始计时，过30秒后再发心跳 。
      if (this.resetsinter) {
        clearInterval(this.resetsinter);
        this.resetsinter = null;
      }
      this.resetsinter = setInterval(this.heartbeat, 30000);
      this.reconnectnum = 0;
    },
    heartbeat() {
      const clock = {
        type: "pong",
      };
      this.websocketsend(JSON.stringify(clock));
    },
    websocketonmessage(e) {
      // 数据接收
      console.log(e);
      const data = JSON.parse(e.data);
      switch (data.type) {
        // 服务端ping客户端
        case "firstping":
          const ty = {
            type: "pong",
          };
          this.websocketsend(JSON.stringify(ty));
          this.$cookie.set("client_id", data.client_id);
          break;
        case "ping":
          const clock = {
            type: "pong",
          };
          this.websocketsend(JSON.stringify(clock));
          console.log("心跳回复");
          break;
        case "pingsuccess":
          console.log("ping成功");
          break;
        case "loginsuccess":
          console.log("登录成功");
          break;
        case "wxpay":
          console.log("微信支付结果通知");
          if (data.statu == 2) {
            console.log("微信支付成功");
          } else {
            console.log("微信支付失败");
          }
          break;
        case "real":
          console.log("支付宝实名认证结果通知");
          if (data.statu == 2) {
            console.log(
              "实名认证效验通过，和注册身份证信息对应，前端做进一步的提示和处理"
            );
          } else {
            console.log(
              "实名认证效验不通过，和注册身份证信息不对应，前端做进一步的提示和处理"
            );
          }
          break;
        case "downcard":
          console.log("下载最新卡片余额记录进度通知");
          if (data.statu == 2) {
            console.log("同步完成了一个".data.data);
            console.log(
              "全部同步完了，进度完成，可以开始下载了，前端需获悉用户下载的总数，然后不断地递减1,但不管的递减多少，3分钟后进度条就全部跑完。"
            );
          }
          break;
        case "hfpay":
          if (data.statu == 2) {
            console.log("第三方支付成功");
          } else {
            console.log("第三方支付失败");
          }
          break;
        case "gopencard":
          this.refreshcon();
          console.log(
            "开卡成功一张!!" + this.successCardNums + "-" + this.nums
          );

          if (data.statu == 2) {
            this.successCardNums = this.successCardNums + 1;
            this.$message({
              message: "开卡成功 " + this.successCardNums + "/" + this.nums,
              type: "success",
            });
            if (this.successCardNums == this.nums) {
              (this.dialogVisible = false),
                this.lods.close(),
                (this.successCardNums = 0);
            }
          }
          if (data.statu == 1) {
            this.successCardNums = this.successCardNums + 1;
            this.$message({
              message: "开卡失败1张!",
              type: "error",
            });
            if (this.successCardNums == this.nums) {
              (this.dialogVisible = false),
                this.lods.close(),
                (this.successCardNums = 0);
            }
          }
          break;
        case "opencard":
          this.refreshcon();
          console.log("开卡成功一张!!");
          if (data.statu == 2) {
            this.successCardNums = this.successCardNums + 1;
            this.$message({
              message: "开卡成功 " + this.successCardNums + "/" + this.nums,
              type: "success",
            });
            if (this.successCardNums == this.nums) {
              (this.dialogVisible = false),
                this.lods.close(),
                (this.successCardNums = 0);
            }
          }
          if (data.statu == 1) {
            this.successCardNums = this.successCardNums + 1;
            this.$message({
              message: "开卡失败1张!",
              type: "error",
            });
            if (this.successCardNums == this.nums) {
              (this.dialogVisible = false),
                this.lods.close(),
                (this.successCardNums = 0);
            }
          }
          break;
      }
    },
  },
  created() {
    //   this.getCards()
    //   this.initWebSocket()
    this.getBill();
  },
  mounted() { },
};
</script>

<style scoped>
::v-deep .el-input__inner {
  border: none !important;
  border-radius: 0px !important;
  border-bottom: 1.5px solid #777 !important;
  color: #000000;
  font-size: 16px;
}

::v-deep .el-input__inner:focus {
  color: #0ba1d3;
  border: none !important;
  border-radius: 0px !important;
  border-bottom: 2px solid #CB0C9F !important;
}

.zd_n_box {
  width: 50%;
  display: inline-block;
}

.billInfo_box {

  padding: 0 4%;
}

.billInfo_box_title {
  font-size: 20px;
  font-family: 'pingfang';
  color: #000000;
  font-weight: bold;
}

.billInfo_box_form {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
}

.billInfo_box_form>.item {
  margin-left: 2.5%;
}

.filed {
  margin: 8px 0;
  font-family: 'pingfang';
  font-size: 14px;
  font-weight: 600;
  color: #777;
}

.setBillBtn {
  width: 80px;
  height: 40px;
  font-size: 16px;
  font-family: 'pingfang';
  border-radius: 4px;
  background-color: #CB0C9F;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
}

.zd_box {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.img_svg {
  position: absolute;
  right: 0px;
  width: 300px;
  height: 300px;
}

.footGourp {
  display: flex;
  justify-content: flex-end;
  padding: 0 4%;
}

i {
  padding-right: 5px;
}

.cardHead {
  font-size: 0.9em;
  text-align: center;
  padding: 2% 0%;
}

.bg-card {
  background: #fff;
  -webkit-box-shadow: 4px 4px 40px rgb(185, 192, 199, 0.3);
  box-shadow: 4px 4px 40px rgb(185, 192, 199, 0.3);
  border-color: rgb(185, 192, 199, 0.3);
  font-size: 1.5em;
  border-radius: 50%;
  font-weight: bold;
  cursor: pointer;
}

.cardTitle {
  font-size: 0.5em;
  margin-left: 4%;
  padding-bottom: 4%;
}

.el-descriptions {
  padding-bottom: 5% !important;
  margin-left: 4% !important;
  margin-right: 4% !important;
}

.box {
  width: 400px;

  .top {
    text-align: center;
  }

  .left {
    float: left;
    width: 60px;
  }

  .right {
    float: right;
    width: 60px;
  }

  .bottom {
    clear: both;
    text-align: center;
  }

  .item {
    margin: 4px;
  }

  .left .el-tooltip__popper,
  .right .el-tooltip__popper {
    padding: 8px 10px;
  }
}

.tips {
  position: relative;
  width: 93%;
  top: 0;
  margin-bottom: 5%;
}

.el-col-6 {
  padding: 0 10px !important;
}

.dh {
  position: relative;
  -moz-box-shadow: 0px 0px 3px 1px rgb(185, 192, 199, 0.3);
  -webkit-box-shadow: 0px 0px 3px 1px rgb(185, 192, 199, 0.3);
  box-shadow: 0px 0px 3px 1px rgb(185, 192, 199, 0.3);
  margin-bottom: 40px;
}

.dh:hover {
  -webkit-animation: upmove 1s ease;
  -o-animation: upmove 1s ease;
  animation: upmove 1s ease;
  animation-fill-mode: forwards;
}

@keyframes upmove {
  from {
    bottom: 0px;
    -moz-box-shadow: 0px 0px 0px 0px #ffffff;
    -webkit-box-shadow: 0px 0px 0px 0px #ffffff;
    box-shadow: 0px 0px 0px 0px #ffffff;
  }

  to {
    -moz-box-shadow: 0px 0px 10px 6px rgb(64, 158, 255, 0.2);
    -webkit-box-shadow: 0px 0px 10px 6px rgb(64, 158, 255, 0.2);
    box-shadow: 0px 0px 10px 6px rgb(64, 158, 255, 0.2);
    bottom: 10px;
  }
}

.el-input {
  width: 90%;
}

.dialog-footer {
  width: 50%;
  position: relative;
  left: 75%;
}
</style>
