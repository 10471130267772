<template>
  <el-main>
    <div class="title_tab">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">{{ $t('businessHistory.home') }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t('businessHistory.cardManagement') }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t('businessHistory.title') }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <div class="user_box">
            <el-form :model="form_carTrade" ref="form_carTrade" :label-position="labelPosition">
              <!-- 输入卡号 -->
              <el-input v-model="form_carTrade.cardNo" prefix-icon="el-icon-tickets"
                :placeholder="$t('businessHistory.des1')">
              </el-input>
              <!-- 操作事件 -->
              <el-select v-model="selelctRechargeStatusVal" :placeholder="$t('businessHistory.des2')" clearable>
                <el-option v-for="item in selelctRechargeStatus" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <!-- 交易状态 -->
              <el-select v-model="statusVal" :placeholder="$t('businessHistory.des3')" clearable>
                <el-option v-for="item in selectStatusVal" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <!-- 日期选择 -->
              <el-date-picker v-model="dataTimes" type="daterange" align="right" unlink-panels range-separator="-"
                :start-placeholder="$t('businessHistory.startDate')" :end-placeholder="$t('businessHistory.endDate')"
                :picker-options="pickerOptions2" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
              </el-date-picker>
              <el-button v-no-more-click icon="el-icon-search" @click="getCardDetails()">{{
                $t('businessHistory.search') }}</el-button>
              <el-button v-no-more-click icon="el-icon-refresh" @click="getCardDetails('1')">{{
                $t('businessHistory.flushed') }}
              </el-button>
              <el-button v-no-more-click icon="el-icon-download" @click="exportCardDetails()">{{
                $t('businessHistory.download') }}
              </el-button>
            </el-form>
          </div>
          <!-- 表格 + 分页 -->
          <div class="user_box">
            <el-table :data="tableData" v-loading="vLoading" style="width: 100%" height="660" max-height="700"
              size="small" highlight-current-row>
              <el-table-column :label="$t('businessHistory.username')" width="140" prop="users">
              </el-table-column>
              <el-table-column :label="$t('businessHistory.operationalEvents')" width="200">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.event_type === 'Auth'" effect="dark" type="success">
                    {{ $t('saleType.authorizedConsumption') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'Reversal'" effect="dark" type="warning">
                    {{ $t('saleType.refund') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'card_out'" effect="dark" type="warning">
                    {{ $t('saleType.theCardIsTransferredOut') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.event_type === 'card_in'" effect="dark" type="warning">
                    {{ $t('saleType.cardTransferIn') }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column :label="$t('businessHistory.cardNumber')" prop="card_num" width="160">
              </el-table-column>
              <el-table-column :label="$t('businessHistory.theNameOfTheMerchant')" prop="shopname" width="200">
              </el-table-column>
              <el-table-column :label="$t('businessHistory.tradingHours')" prop="createtime" width="160">
              </el-table-column>
              <el-table-column :label="$t('businessHistory.theAmountOfTheTransaction')" width="120" prop="money">
              </el-table-column>
              <el-table-column :label="$t('businessHistory.theCurrencyOfTheTransaction')" width="120" prop="moneytype">
              </el-table-column>
              <el-table-column :label="$t('businessHistory.transactionStatus')" width="160">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.statu === 1" type="success">
                    {{ $t('saleStatus.theTransactionWasSuccessful') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.statu === 2" type="danger">
                    {{ $t('saleStatus.theTransactionFailed') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.statu === 3" type="warning">
                    {{ $t('saleStatus.trading') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.statu === 4" type="success">
                    {{ $t('saleStatus.theTransactionReversalIsSuccessful') }}
                  </el-tag>
                  <el-tag v-else-if="scope.row.statu === 5" type="danger">
                    {{ $t('saleStatus.transactionReversalFailed') }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column :label="$t('businessHistory.transactionDescription')" prop="desct">
                <template slot-scope="scope">
                  {{ seleDesTop(scope.row.desct) }}
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination align="center" @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[100, 500, 1000]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totals">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      totals: 0,
      name: "交易记录",
      selelctRechargeStatusVal: "",
      currentPage: 1, // 当前页码
      pageSize: 100, // 每页数据显示 条数
      dataTimes: [],
      labelPosition: "right",
      statusVal: "",
      tableData: [],
      vLoading: false,
      form_carTrade: {
        cardNo: "",
      },
      selectStatusVal: [
        {
          value: "1",
          label: this.$t('saleStatus.theTransactionWasSuccessful'),
        },
        {
          value: "2",
          label: this.$t('saleStatus.theTransactionFailed'),
        },
        {
          value: "3",
          label: this.$t('saleStatus.trading'),
        },
        {
          value: "4",
          label: this.$t('saleStatus.theTransactionReversalIsSuccessful'),
        },
        {
          value: "5",
          label: this.$t('saleStatus.transactionReversalFailed'),
        },
      ],
      // 支付状态
      selelctRechargeStatus: [
        // {
        //   value: "6810",
        //   label: "消费授权(通过)",
        // },
        // {
        //   value: "6890",
        //   label: "消费授权(拒绝)",
        // },
        // {
        //   value: "6510",
        //   label: "退款授权(通过)",
        // },
        // {
        //   value: "6590",
        //   label: "退款授权(拒绝)",
        // },
        // {
        //   value: "6930",
        //   label: "消费授权冲正",
        // },
        // {
        //   value: "6940",
        //   label: "退款授权冲正",
        // },
        // {
        //   value: "auth",
        //   label: this.$t('saleType.consume'),
        // },
        // {
        //   value: "corrective_auth",
        //   label: this.$t('saleType.correctionOfAuthorization'),
        // },
        // {
        //   value: "verification",
        //   label: this.$t('saleType.verify'),
        // },
        // {
        //   value: "void",
        //   label: this.$t('saleType.quash'),
        // },
        // {
        //   value: "refund",
        //   label: this.$t('saleType.refund'),
        // },
        // {
        //   value: "corrective_refund",
        //   label: this.$t('saleType.validRefunds'),
        // },
        // {
        //   value: "recharge",
        //   label: this.$t('saleType.recharge'),
        // },
        // {
        //   value: "recharge_return",
        //   label: this.$t('saleType.cardBalanceRefund'),
        // },
        // {
        //   value: "discard_recharge_return",
        //   label: this.$t('saleType.theCancelledCardIsReturned'),
        // },
        // {
        //   value: "service_fee",
        //   label: this.$t('saleType.serviceCharge'),
        // },
        // {
        //   value: "refund_reversal",
        //   label: this.$t('saleType.revocationOfRefunds'),
        // },
        {
          value: "Auth",
          label: this.$t('saleType.authorizedConsumption'),
        },
        {
          value: "Reversal",
          label: this.$t('saleType.refund'),
        },
        {
          value: "card_out",
          label: this.$t('saleType.theCardIsTransferredOut'),
        },
        {
          value: "card_in",
          label: this.$t('saleType.cardTransferIn'),
        },
      ],
      pickerOptions2: {
        shortcuts: [
          {
            text: this.$t('businessHistory.theLastWeek'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$t('businessHistory.theLastMonth'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: this.$t('businessHistory.lastThreeMonths'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      TsDes: [
        {
          key: 'DECLINED INSUFFICIENT_FUNDS',
          value: this.$t('queryCard.tsdes1')
        },
        {
          key: 'DECLINED INVALID_CVC',
          value: this.$t('queryCard.tsdes2')
        },
        {
          key: 'DECLINED LIFE_TIME_AMOUNT_EXCEEDED',
          value: this.$t('queryCard.tsdes1')
        },
        {
          key: 'DECLINED INVALID_EXPIRATION_DATE',
          value: this.$t('queryCard.tsdes3')
        },
        {
          key: 'DECLINED REASON_UNKNOWN',
          value: this.$t('queryCard.tsdes4')
        },
        {
          key: 'DECLINED NOT_ACTIVATED_CARD',
          value: this.$t('queryCard.tsdes5')
        },
        {
          key: 'DECLINED Transaction not permitted to cardholder',
          value: this.$t('queryCard.tsdes6')
        },
        {
          key: 'DECLINED PLATFORM_SECURITY_VIOLATION',
          value: this.$t('queryCard.tsdes7')
        },
        {
          key: 'DECLINED EXPIRED_CARD',
          value: this.$t('queryCard.tsdes8')
        },
        {
          key: 'DECLINED Bad CVV2,CID,Expiry Date',
          value: this.$t('queryCard.tsdes9')
        },
        {
          key: 'DECLINED MCC_BLOCKED',
          value: this.$t('queryCard.tsdes10')
        },
        {
          key: 'DECLINED Invalid Transaction(CVC1,CVV2,CID,iCVV Format Error)',
          value: this.$t('queryCard.tsdes11')
        },
        {
          key: 'DECLINED Do not honour',
          value: this.$t('queryCard.tsdes12')
        },
        {
          key: 'DECLINED Suspected Fraud',
          value: this.$t('queryCard.tsdes13')
        },
        {
          key: 'DECLINED Suspected Fraud',
          value: this.$t('queryCard.tsdes14')
        },
      ]
    };
  },
  methods: {
    seleDesTop(des) {
      const result = this.TsDes.filter(item => item.key == des)
      if (result.length > 0) {
        return result[0].value
      } else {
        return des
      }
    },
    // 格式化时间
    getTime(/** timestamp=0 **/) {
      const ts = arguments[0] || 0;
      let t, y, m, d, h, i, s;
      t = ts ? new Date(ts * 1000) : new Date();
      y = t.getFullYear();
      m = t.getMonth() + 1;
      d = t.getDate();
      h = t.getHours();
      i = t.getMinutes();
      s = t.getSeconds();
      return (
        y +
        "-" +
        (m < 10 ? "0" + m : m) +
        "-" +
        (d < 10 ? "0" + d : d) +
        " " +
        (h < 10 ? "0" + h : h) +
        ":" +
        (i < 10 ? "0" + i : i) +
        ":" +
        (s < 10 ? "0" + s : s)
      );
    },
    // 页数
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.getCardDetails();
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getCardDetails();
    },
    // 导出交易记录
    exportCardDetails() {
      if (!this.dataTimes) {
        this.dataTimes = ["", ""]
      }
      let params = {
        cardnum: this.form_carTrade.cardNo,
        eventype: this.selelctRechargeStatusVal,
        start: this.dataTimes[0],
        statu: this.statusVal || 0,
        ends: this.dataTimes[1],
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/exportShopRecord", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          // console.log(res);
          const contentType = res.headers.get("Content-Type");
          const fileType = contentType.split("/")[1].split(";")[0];
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const a = document.createElement("a");
          a.href = url;
          a.download = `exportedFile.${fileType}`; // 指定文件名
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 获取消费记录
    getCardDetails(rf) {
      if (rf == "1") {
        this.currentPage = 1;
        this.pageSize = 100;
        this.form_carTrade.cardNo = "";
        this.selelctRechargeStatusVal = "";
        this.statusVal = "";
        this.dataTimes = ["", ""]
      }
      if (!this.dataTimes) {
        this.dataTimes = ["", ""]
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        cardnum: this.form_carTrade.cardNo,
        eventype: this.selelctRechargeStatusVal,
        start: this.dataTimes[0],
        statu: this.statusVal,
        ends: this.dataTimes[1],
      };
      params = this.$lmjm(params);
      this.vLoading = true
      const result = this.$http.post("/api/v1/getShopRecord", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.tableData = res.data.data.data;
          this.totals = Number(res.data.data.total);
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].createtime = this.getTime(
              res.data.data.data[i].createtime
            );
          }
          this.vLoading = false
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  created() {
    this.getCardDetails();
  },
  mounted() { },
};
</script>

<style scoped>
.el-select,
.el-input--prefix {
  margin-right: 1%;
}

.el-input--prefix {
  width: 15%;
}

.el-range-editor {
  margin-right: 3%;
}

.el-button {
  margin-right: 2%;
}
</style>
