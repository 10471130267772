<template>
  <el-main>
    <div class="title_tab">{{ name }}</div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <div class="user_box">
            <el-button @click="newCardsdialogVisible = true" icon="el-icon-plus">新增公告</el-button>
            <br />
            <br />
            <!-- 公告列表 -->
            <el-table :data="noticeData" v-loading="vLoading" size="small" style="width: 100%" height="660"
              max-height="800">
              <!-- id -->
              <el-table-column prop="id" label="公告id" width="120">
              </el-table-column>
              <!-- 公告标题 -->
              <el-table-column prop="title" label="公告标题" width="300" :show-overflow-tooltip="true">
              </el-table-column>
              <!-- 内容 -->
              <el-table-column prop="content" label="内容" :show-overflow-tooltip="true">
              </el-table-column>
              <!-- 公告类型 -->
              <el-table-column prop="type" label="公告类型" width="120">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.type === 1">
                    正常
                  </el-tag>
                  <el-tag v-else-if="scope.row.type === 2" type="warning">
                    弹窗
                  </el-tag>
                  <el-tag v-else-if="scope.row.type === 3" type="info">
                    停用
                  </el-tag>
                </template>
              </el-table-column>
              <!-- 操作 -->
              <el-table-column label="操作" width="180">
                <template slot-scope="scope">
                  <el-button type="primary" @click="editNotice(scope.row)">编辑</el-button>
                  <el-button type="danger" @click="deleteNotice(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination align='center' @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[20, 30, 40]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="noticeTotal">
            </el-pagination>
          </div>
          <!-- 新增公告 -->
          <el-dialog custom-class="ls" title="新增公告" :visible.sync="newCardsdialogVisible" width="60%">
            <el-form :label-position="labelPosition" label-width="80px" :model="notice_form">
              <!-- 标题 -->
              <el-form-item label="标题">
                <el-input v-model="notice_form.title"></el-input>
              </el-form-item>
              <!-- 内容 -->
              <el-form-item label="内容">
                <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 50 }" v-model="notice_form.body"></el-input>
              </el-form-item>
              <!-- 公告类型 -->
              <el-form-item label="公告类型">
                <el-select v-model="notice_form.noticeType" placeholder="请选择公告类型">
                  <el-option label="正常" :value="1"></el-option>
                  <el-option label="弹窗" :value="2"></el-option>
                  <el-option label="停用" :value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div style="float: right;position: relative;bottom: 1.5em;">
              <el-button @click="addNotice(), newCardsdialogVisible = false" v-no-more-click>确
                定</el-button>
              <el-button @click="newCardsdialogVisible = false">取 消</el-button>
            </div>
          </el-dialog>
          <!-- 编辑公告 -->
          <el-dialog custom-class="ls" title="编辑公告" :visible.sync="newCardsdialogVisible1" width="60%">
            <el-form :label-position="labelPosition" label-width="80px" :model="noticeEdit_form">
              <!-- 标题 -->
              <el-form-item label="标题">
                <el-input v-model="noticeEdit_form.title"></el-input>
              </el-form-item>
              <!-- 内容 -->
              <el-form-item label="内容">
                <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 50 }" v-model="noticeEdit_form.content">
                </el-input>
              </el-form-item>
              <!-- 公告类型 -->
              <el-form-item label="公告类型">
                <el-select v-model="noticeEdit_form.type" placeholder="请选择公告类型">
                  <el-option label="正常" :value="1"></el-option>
                  <el-option label="弹窗" :value="2"></el-option>
                  <el-option label="停用" :value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div style="float: right;position: relative;bottom: 1.5em;">
              <el-button @click="editNoticeFunction(), newCardsdialogVisible1 = false" v-no-more-click>确
                定</el-button>
              <el-button @click="newCardsdialogVisible1 = false">取 消</el-button>
            </div>
          </el-dialog>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      name: '公告管理',
      notice_form: {},
      edId: '',
      labelPosition: 'right',
      noticeTotal: 0,
      noticeData: [
      ],
      noticeEdit_form: {
        content: '',
        title: '',
        type: ''
      },
      vLoading: false,
      currentPage: 1, // 当前页码
      pageSize: 20, // 每页数据显示 条数
      newCardsdialogVisible: false,
      newCardsdialogVisible1: false
    }
  },
  methods: {
    // 页数
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize = val
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val
    },
    // 编辑公告方法
    editNoticeFunction() {
      let params = {
        id: this.edId,
        title: this.noticeEdit_form.title,
        content: this.noticeEdit_form.content,
        type: this.noticeEdit_form.type
      }
      params = this.$lmjm(params)
      const result = this.$http.post('/api/v1/eidtNotice', params, {
        headers: {
          token: window.sessionStorage.getItem('token')
        }
      })
      result.then((res) => {
        if (res.data.code == '200') {
          this.$message({
            message: '修改成功!',
            type: 'success'
          }), this.getNotice(), this.edId = ''
        }
      }, (error) => {
        console.log('错误:' + error)
      })
    },
    // 编辑公告
    editNotice(row) {
      this.edId = row.id
      this.newCardsdialogVisible1 = true
      this.noticeEdit_form.content = row.content
      this.noticeEdit_form.title = row.title
      this.noticeEdit_form.type = row.type
    },
    // 删除公告
    deleteNotice(row) {
      this.$confirm('是否删除此公告?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          id: row.id
        }
        params = this.$lmjm(params)
        const result = this.$http.post('/api/v1/delNotice', params, {
          headers: {
            token: window.sessionStorage.getItem('token')
          }
        })
        result.then((res) => {
          if (res.data.code == '200') {
            this.$message({
              message: '删除成功!',
              type: 'success'
            }), this.getNotice()
          }
        }, (error) => {
          console.log('错误:' + error)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 新增公告
    addNotice() {
      let params = {
        title: this.notice_form.title,
        content: this.notice_form.body,
        type: this.notice_form.noticeType
      }
      params = this.$lmjm(params)
      const result = this.$http.post('/api/v1/addNotice', params, {
        headers: {
          token: window.sessionStorage.getItem('token')
        }
      })
      result.then((res) => {
        if (res.data.code == '200') {
          this.$message({
            message: '添加成功!',
            type: 'success'
          }), this.getNotice()
        }
      }, (error) => {
        console.log('错误:' + error)
      })
    },
    // 获取公告
    getNotice() {
      let params = {
        page: this.currentPage,
        limit: this.pageSize
      }
      params = this.$lmjm(params)
      this.vLoading = true
      const result = this.$http.post('/api/v1/getNoticeList', params, {
        headers: {
          token: window.sessionStorage.getItem('token')
        }
      })
      result.then((res) => {
        this.noticeData = res.data.data.data
        this.noticeTotal = res.data.data.total
        this.vLoading = false
      }, (error) => {
        console.log('错误:' + error)
      })
    }
  },
  created() {
    this.getNotice()
  },
  mounted() {

  }
}
</script>

<style scoped></style>
