<template>
  <el-main>
    <div class="title_tab">{{ name }}</div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <div class="user_box">
            <!-- 用户名 -->
            <el-input v-model="userNames" placeholder="请输入用户名" style="width: 15%;margin-right: 30px;"></el-input>
            <!-- 充值渠道 -->
            <el-select v-model="czVal" placeholder="登录状态" clearable style="width: 8%;margin-right: 30px;">
              <el-option v-for="item in selectCz" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-button style="margin-bottom: 20px;margin-left: 20px;" icon="el-icon-search" @click="getLoginlog()">查
              询</el-button>
            <el-button v-no-more-click icon="el-icon-refresh" @click="getLoginlog('rf')">刷 新
            </el-button>
            <el-table :data="tableData" v-loading="vLoading" style="width: 100%" height="670" max-height="800"
              size="small" highlight-current-row>
              <el-table-column label="用户名" prop="username">
              </el-table-column>
              <el-table-column label="登录IP" prop="lastip">
              </el-table-column>
              <el-table-column label="登录地址" prop="lastaddress">
              </el-table-column>
              <el-table-column label="登录时间" prop="createtime">
              </el-table-column>
              <el-table-column prop="types" label="登录状态" width="220">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.types === 1" type="success">
                    登录成功
                  </el-tag>
                  <el-tag v-if="scope.row.types === 2" type="danger">
                    停用登录失败
                  </el-tag>
                  <el-tag v-if="scope.row.types === 3" type="danger">
                    待审核登录失败
                  </el-tag>
                  <el-tag v-if="scope.row.types === 4" type="danger">
                    审核不通过登录失败
                  </el-tag>
                  <el-tag v-if="scope.row.types === 5" type="danger">
                    密码错误登录失败
                  </el-tag>
                  <el-tag v-if="scope.row.types === 6" type="danger">
                    谷歌验证码错误
                  </el-tag>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination align='center' @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[100, 200, 300, 500]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="rechageTotal">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      userNames: '',
      czVal: '',
      rechageTotal: 0,
      tableData: [],
      vLoading: false,
      currentPage: 1,
      pageSize: 100,
      name: '登录日志',
      selectCz: [{
        value: 1,
        label: '登录成功'
      }, {
        value: 2,
        label: '停用登录失败'
      }, {
        value: 3,
        label: '待审核登录失败'
      }, {
        value: 4,
        label: '审核不通过登录失败'
      }, {
        value: 5,
        label: '错误登录失败'
      }, {
        value: 6,
        label: '谷歌验证码错误'
      }]
    }
  },
  methods: {
    // 格式化时间
    getTime(/** timestamp=0 **/) {
      const ts = arguments[0] || 0
      let t, y, m, d, h, i, s
      t = ts ? new Date(ts * 1000) : new Date()
      y = t.getFullYear()
      m = t.getMonth() + 1
      d = t.getDate()
      h = t.getHours()
      i = t.getMinutes()
      s = t.getSeconds()
      // 可依据须要在这里定义时间格式
      return y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10

        ? '0' + d
        : d) + ' ' + (h < 10 ? '0' + h : h) + ':' + (i < 10 ? '0' + i : i) + ':' + (s < 10 ? '0' + s : s)
    },
    // 页数
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize = val
      // this.getAllUserRechargedata()
      this.getLoginlog()
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val
      // this.getAllUserRechargedata()
      this.getLoginlog()
    },
    getLoginlog(sl) {
      if (sl == 'rf') {
        this.userNames = ''
        this.czVal = ''
        this.pageSize = 100
        this.currentPage = 1
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        keyword: this.userNames,
        types: this.czVal
      }
      params = this.$lmjm(params)
      this.vLoading = true
      const result = this.$http.post('/api/v1/getLoginlog', params, {
        headers: {
          token: window.sessionStorage.getItem('token')
        }
      })
      result.then((res) => {
        this.rechageTotal = res.data.data.total
        this.tableData = res.data.data.data
        for (let i = 0; i < this.tableData.length; i++) {
          this.tableData[i].createtime = this.getTime(res.data.data.data[i].createtime)
        }
        this.vLoading = false
      }, (error) => {
        console.log(error)
      })
    }
  },
  created() {
    this.getLoginlog()
  },
  mounted() {

  }
}
</script>

<style scoped></style>
