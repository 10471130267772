<template>
  <el-main>
    <div class="title_tab">{{ name }}</div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <div class="user_box">
            <div style="margin-bottom: 20px;">
              <!-- 输入卡号 -->
              <el-input style="width: 15%;" v-model="searchKey" prefix-icon="el-icon-tickets" placeholder="请输入用户名">
              </el-input>
              <!-- 交易币种 -->
              <el-select v-model="types" placeholder="交易币种" clearable style="margin-right: 20px;margin-left: 20px;">
                <el-option v-for="item in typesoptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <!-- 日期选择 -->
              <el-date-picker v-model="dataTimes" type="daterange" align="right" unlink-panels range-separator="-"
                start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions2"
                format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
              </el-date-picker>
              <el-button style="margin-left: 30px;" icon="el-icon-search" @click="getUserWithdinfo()">搜 索
              </el-button>
              <el-button icon="el-icon-refresh" @click="getUserWithdinfo('1')">刷 新</el-button>
            </div>
            <el-table :data="disData" v-loading="vLoading" size="small" style="width: 100%;" height="670"
              max-height="800">
              <el-table-column prop="users" label="代理商用户名">
              </el-table-column>
              <el-table-column prop="fusers" label="充值用户名">
              </el-table-column>
              <el-table-column prop="money" label="充值到账金额">
              </el-table-column>
              <el-table-column prop="ctypes" label="交易类型">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.ctypes === 1" type="success">
                    代理商分成
                  </el-tag>
                  <el-tag v-else-if="scope.row.ctypes === 2" type="danger">
                    用户提现扣减
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="types" label="分成币种">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.types === 1">
                    CNY
                  </el-tag>
                  <el-tag v-else-if="scope.row.types === 2">
                    USD
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="fenmoney" label="分成金额">
                <template slot-scope="scope">
                  <span v-if="scope.row.ctypes === 2" style="color: red;">
                    - {{ scope.row.fenmoney }}
                  </span>
                  <span v-else-if="scope.row.ctypes === 1">
                    {{ scope.row.fenmoney }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="aftermoney" label="分成总余额">
              </el-table-column>
              <el-table-column prop="lv" label="分成汇率">
              </el-table-column>
              <el-table-column prop="createtime" label="分成时间">
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination align='center' @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[100, 500, 1000, 1500]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      name: '分成记录',
      disData: [],
      currentPage: 1,
      pageSize: 100,
      total: 0,
      searchKey: '',
      dataTimes: [],
      vLoading: false,
      types: '',
      typesoptions: [{
        value: '1',
        label: 'CNY'
      }, {
        value: '2',
        label: 'USD'
      }],
      pickerOptions2: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  methods: {
    // 格式化时间
    getTime( /** timestamp=0 **/) {
      const ts = arguments[0] || 0
      let t, y, m, d, h, i, s
      t = ts ? new Date(ts * 1000) : new Date()
      y = t.getFullYear()
      m = t.getMonth() + 1
      d = t.getDate()
      h = t.getHours()
      i = t.getMinutes()
      s = t.getSeconds()
      return y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d) + ' ' + (h < 10 ? '0' + h : h) +
        ':' + (
          i < 10 ? '0' + i : i) + ':' + (s < 10 ? '0' + s : s)
    },
    // 页数
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize = val
      this.getUserWithdinfo()
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val
      this.getUserWithdinfo()
    },
    // 获取用户提现记录
    getUserWithdinfo(rf) {
      if (rf == '1') {
        this.currentPage = 1
        this.pageSize = 100
        this.searchKey = ''
        this.types = ''
        this.dataTimes = []
      }
      if (this.dataTimes[0] == undefined && this.dataTimes[1] == undefined) {
        this.dataTimes[0] = ""
        this.dataTimes[1] = ""
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        users: this.searchKey,
        types: this.types,
        start: this.dataTimes[0],
        ends: this.dataTimes[1]
      }
      params = this.$lmjm(params)
      this.vLoading = true
      const result = this.$http.post('/api/v1/getDivideInfoData', params, {
        headers: {
          token: window.sessionStorage.getItem('token')
        }
      })
      result.then((res) => {
        this.disData = res.data.data.data
        this.total = res.data.data.total
        for (let i = 0; i < this.disData.length; i++) {
          this.disData[i].createtime = this.getTime(res.data.data.data[i].createtime)
        }
        this.vLoading = false
      }, (error) => {
        console.log(error)
      })
    }
  },
  created() {
    this.getUserWithdinfo()
  },
  mounted() { }
}
</script>

<style scoped></style>
