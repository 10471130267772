<template>
  <el-main>
    <div class="title_tab">{{ name }}</div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <div class="user_box">
            <div style="margin-bottom: 20px;">
              <!-- 输入卡号 -->
              <el-input style="width: 15%;" v-model="searchKey" prefix-icon="el-icon-tickets" placeholder="请输入用户名">
              </el-input>
              <!-- 提现状态 -->
              <el-select v-model="status" placeholder="提现状态" clearable style="margin-right: 20px;margin-left: 20px;">
                <el-option v-for="item in statusoptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <!-- 交易币种 -->
              <el-select v-model="types" placeholder="交易币种" clearable style="margin-right: 20px;">
                <el-option v-for="item in typesoptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <!-- 日期选择 -->
              <el-date-picker v-model="dataTimes" type="daterange" align="right" unlink-panels range-separator="-"
                start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions2"
                format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
              </el-date-picker>
              <el-button style="margin-left: 30px;" icon="el-icon-search" @click="getUserWithdinfo()">搜 索
              </el-button>
              <el-button icon="el-icon-refresh" @click="getUserWithdinfo('1')">刷 新</el-button>
            </div>
            <el-table :data="disData" v-loading="vLoading" size="small" style="width: 100%;" height="670"
              max-height="800">
              <el-table-column prop="order_num" label="订单号" width="190">
              </el-table-column>
              <el-table-column label="用户类型" width="100">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.utypes === 1">
                    普通用户
                  </el-tag>
                  <el-tag v-else-if="scope.row.utypes === 2" type="warning">
                    代理商
                  </el-tag>
                  <el-tag v-else-if="scope.row.utypes === 3" type="danger">
                    管理员
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="users" label="提现用户名" width="120">
              </el-table-column>
              <el-table-column prop="money" label="客户提现金额" width="110">
                <template slot-scope="scope">
                  <el-tag>
                    {{ scope.row.money }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="endmoney" label=" 财务打款金额" width="110">
                <template slot-scope="scope">
                  <el-tag style="color: #ffffff;" color="#725CDA"> {{ scope.row.endmoney }} </el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="types" label="提现币种" width="80">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.types === 1">
                    CNY
                  </el-tag>
                  <el-tag v-else-if="scope.row.types === 2" type="danger">
                    USD
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="status" label="提现状态" width="100">
                <template slot-scope="scope">
                  <el-tag type="warning" v-if="scope.row.status === 1">
                    审核中
                  </el-tag>
                  <el-tag type="success" v-else-if="scope.row.status === 2">
                    审核通过
                  </el-tag>
                  <el-tag type="danger" v-else-if="scope.row.status === 3">
                    审核驳回
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="zfbuser" label="支付宝账号" width="180">
              </el-table-column>
              <el-table-column prop="names" label="支付宝姓名" width="100">
              </el-table-column>
              <el-table-column prop="remark" label="提现申请备注" :show-overflow-tooltip="true" width="120">
              </el-table-column>
              <el-table-column prop="refusecon" label="审批备注" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <el-tag type="warning" v-if="scope.row.refusecon === null">
                    审核中
                  </el-tag>
                  <span v-else="">
                    {{ scope.row.refusecon }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="replaytime" label="审核时间" width="170">
                <template slot-scope="scope">
                  <el-tag type="warning" v-if="scope.row.replaytime === null">
                    审核中
                  </el-tag>
                  <span v-else="">
                    {{ scope.row.replaytime }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="createtime" label="提现时间" width="170">
              </el-table-column>
              <el-table-column prop="curlv" label="提现汇率" width="80">
              </el-table-column>
              <!-- 前端搜索框 -->
              <el-table-column label="操作" fixed="right" width="120px">
                <template slot-scope="scope">
                  <el-button size="mini" v-no-more-click v-if="scope.row.status === 1" type="primary"
                    @click="dialogFormVisible = true, setWiRes(scope.row.id)">
                    审核
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination align='center' @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[20, 30, 40]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </div>
        <el-dialog title="提现审核" :visible.sync="dialogFormVisible" width="20%" custom-class="ls">
          <div>
            <el-radio v-model="radio" label="2">审核通过</el-radio>
            <el-radio v-model="radio" label="3">审核驳回</el-radio>
          </div>
          <div style="margin-top: 20px;padding-bottom: 35px;">

            <el-autocomplete class="inline-input" @select="handleSelect" v-model="shremark"
              :fetch-suggestions="querySearch" placeholder="请输入内容">
              <template slot="prepend">审核备注:</template>
            </el-autocomplete>
          </div>
          <div style="position: absolute;right: 15px;bottom: 10px;">
            <el-button @click="WiRes()">确定</el-button>
          </div>
        </el-dialog>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      name: '提现记录',
      disData: [],
      dialogFormVisible: false,
      currentPage: 1,
      radio: '2',
      money: '50',
      endmoney: '0.00',
      remark: '',
      userNames: this.$cookie.get('user'),
      pageSize: 20,
      total: 0,
      usdmoney: '0.00',
      cnymoney: '0.00',
      zfbuser: '',
      searchKey: '',
      names: '',
      shremark: '',
      dataTimes: [],
      vLoading: false,
      cnylv: '0.00',
      usdlv: '0.0000',
      ids: '',
      status: '',
      types: '',
      restaurants: [],
      statusoptions: [{
        value: '1',
        label: '申请待审核中'
      }, {
        value: '2',
        label: '审核通过'
      }, {
        value: '3',
        label: '审核驳回'
      }],
      typesoptions: [{
        value: '1',
        label: 'CNY'
      }, {
        value: '2',
        label: 'USD'
      }],
      pickerOptions2: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  methods: {
    handleSelect(item) {
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    // 格式化时间
    getTime(/** timestamp=0 **/) {
      const ts = arguments[0] || 0
      let t, y, m, d, h, i, s
      t = ts ? new Date(ts * 1000) : new Date()
      y = t.getFullYear()
      m = t.getMonth() + 1
      d = t.getDate()
      h = t.getHours()
      i = t.getMinutes()
      s = t.getSeconds()
      return y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d) + ' ' + (h < 10 ? '0' + h : h) +
        ':' + (
          i < 10 ? '0' + i : i) + ':' + (s < 10 ? '0' + s : s)
    },
    // 页数
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize = val
      this.getUserWithdinfo()
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val
      this.getUserWithdinfo()
    },
    // 获取用户提现记录
    getUserWithdinfo(rf) {
      if (rf == '1') {
        this.currentPage = 1
        this.pageSize = 10
        this.status = ''
        this.types = ''
        this.dataTimes = []
        this.searchKey = ''
      }
      if (this.dataTimes[0] == undefined && this.dataTimes[1] == undefined) {
        this.dataTimes[0] = ""
        this.dataTimes[1] = ""
      }
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        status: this.status,
        types: this.types,
        start: this.dataTimes[0],
        ends: this.dataTimes[1],
        users: this.searchKey
      }
      params = this.$lmjm(params)
      this.vLoading = true
      const result = this.$http.post('/api/v1/getAllUserWiinfo', params, {
        headers: {
          token: window.sessionStorage.getItem('token')
        }
      })
      result.then((res) => {
        this.cnylv = res.data.data.lvdata.cnylv
        this.usdlv = res.data.data.lvdata.usdlv
        this.disData = res.data.data.data
        this.total = res.data.data.total
        for (let i = 0; i < this.disData.length; i++) {
          this.disData[i].createtime = this.getTime(res.data.data.data[i].createtime)
          if (this.disData[i].replaytime != null) {
            this.disData[i].replaytime = this.getTime(res.data.data.data[i].replaytime)
          }
        }
        this.vLoading = false
      }, (error) => {
        console.log(error)
      })
    },
    // 审核提现回调
    setWiRes(ids) {
      this.ids = ids
    },
    // 审核提现
    WiRes() {
      let params = {
        id: this.ids,
        status: this.radio,
        remark: this.shremark
      }
      params = this.$lmjm(params)
      const result = this.$http.post('/api/v1/setWiRes', params, {
        headers: {
          token: window.sessionStorage.getItem('token')
        }
      })
      result.then((res) => {
        if (res.data.code == '200') {
          this.$message({
            message: '审核成功!',
            type: 'success'
          }), this.getUserWithdinfo(), this.dialogFormVisible = false
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      }, (error) => {
        console.log(error)
      })
    }
  },
  created() {
    this.getUserWithdinfo()
  },
  mounted() {
    this.restaurants = [
      {
        value: "用户取消"
      },
      {
        value: "异常账户"
      },
      {
        value: "支付宝不存在"
      },
      {
        value: "转账未开启"
      },
      {
        value: "支付宝名字不符"
      },
      {
        value: "风控审查"
      }
    ]
  }
}
</script>

<style scoped></style>
