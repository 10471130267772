<template>
  <el-main>
    <div class="title_tab">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">{{ $t('gg.home') }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t('gg.title') }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <!-- 搜索框 -->
          <div class="user_box">
            <el-row :gutter="20">
              <div style="margin-bottom: 20px">
                {{ $t('gg.des1') }}
                <img src="../images/download.png" style="width: 28px" />
              </div>
              <!-- fb -->
              <el-col :span="8">
                <div style="border: 1px solid #725cda; border-radius: 19px;height:710px;position: relative;"
                  class="grid-content bg-purple">
                  <div class="gg_img_box">
                    <img src="../images/fb_d.png" width="100%" />
                    <img src="../images/fb.png" width="8%" style="float: left; margin-left: 8%; margin-top: 5%" />
                  </div>
                  <div class="gg_title">Facebook For Business</div>
                  <div class="gg_content">
                    <p>{{ $t('gg.des11') }}</p>
                    <p>{{ $t('gg.des2') }}</p>
                    <P>{{ $t('gg.des3') }}</P>
                  </div>
                  <div style="
                      position: absolute;
                      width: 40%;
                      left: 50%;
                      transform: translateX(-50%);
                      bottom: 3%;
                    ">
                    <el-button style="width: 100%; font-size: 0.5em">
                      {{ $t('gg.corporateAccountOpening') }}
                    </el-button>
                  </div>
                </div>
              </el-col>
              <!-- tk -->
              <el-col :span="8">
                <div style="border: 1px solid #725cda; border-radius: 19px;height:710px;position: relative;"
                  class="grid-content bg-purple">
                  <div class="gg_img_box">
                    <img src="../images/tk_d.png" width="100%" />
                    <img src="../images/tk.png" width="8%" style="float: left; margin-left: 8%; margin-top: 5%" />
                  </div>
                  <div class="gg_title">TikTok For Business</div>
                  <div class="gg_content">
                    <p>{{ $t('gg.des4') }}</p>
                    <p>{{ $t('gg.des5') }}</p>
                    <P>{{ $t('gg.des6') }}</P>
                  </div>
                  <div style="
                      position: absolute;
                      width: 40%;
                      left: 50%;
                      transform: translateX(-50%);
                      bottom: 3%;
                    ">
                    <el-button style="width: 100%; font-size: 0.5em">
                      {{ $t('gg.corporateAccountOpening') }}
                    </el-button>
                  </div>
                </div>
              </el-col>
              <!-- gg -->
              <el-col :span="8">
                <div style="border: 1px solid #725cda; border-radius: 19px;height:710px;position: relative;"
                  class="grid-content bg-purple">
                  <div class="gg_img_box">
                    <img src="../images/gg_d.png" width="100%" />
                    <img src="../images/gg.png" width="8%" style="float: left; margin-left: 8%; margin-top: 5%" />
                  </div>
                  <div class="gg_title">Google Ads</div>
                  <div class="gg_content">
                    <p>{{ $t('gg.des7') }}</p>
                    <p>{{ $t('gg.des8') }}</p>
                    <P>{{ $t('gg.des9') }}</P>
                  </div>
                  <div style="
                      position: absolute;
                      width: 40%;
                      left: 50%;
                      transform: translateX(-50%);
                      bottom: 3%;
                    ">
                    <el-button style="width: 100%; font-size: 0.5em">
                      {{ $t('gg.corporateAccountOpening') }}
                    </el-button>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <div style="width: 100%; text-align: center; font-size: 1.5em">
        {{ $t('gg.des10') }}
        <br />
        <br />
        <img src="../images/lm.png" width="50%" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('gg.cancel') }}</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      name: "广告投放高额返点",
      activeName: "1",
      dialogVisible: false,
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style scoped>
.gg_img_box {
  width: 100%;
  height: auto;
}

.gg_content {
  font-size: 0.5em;
  margin-left: 18%;
  margin-top: 5%;
}

.gg_title {
  margin-left: 18%;
  margin-top: 5%;
}
</style>
