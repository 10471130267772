<template>
  <el-main>
    <el-dialog :visible.sync="jldlog" width="20%" @close="handleClose">
      <div style="text-align: center" v-if="qrcodeVisible === 0">
        <div style="margin-bottom: 20px; font-size: 20px">
          {{ $t("welcome.customerServiceWeChat") }}
        </div>
        <img :src="wechatUrl" width="200px" />
        <div>{{ $t("welcome.working") }}</div>
      </div>
      <div style="text-align: center" v-if="qrcodeVisible === 1">
        <div style="margin-bottom: 20px; font-size: 20px">
          {{ $t("welcome.mobile") }}
        </div>
        <img src="../images/qrcode.png" width="200px" />
      </div>
      <div style="text-align: center" v-if="noticeInfo">
        <div style="margin-bottom: 20px; font-size: 20px;font-weight: 600;color: #000;">
          <i class="el-icon-bell"></i>{{ $t("welcome.announcement") }}
        </div>
        <div class="notince-info-c" v-html="noticeInfo">
        </div>
      </div>
    </el-dialog>
    <div class="user_title_tab">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">{{
          $t("welcome.home")
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t("welcome.main") }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="menu-container">
        <div class="svg-box mr" v-if="themeVal == 'dark'" @click="changeTheme('light')">
          <svg t="1731671934971" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5570" width="20" height="20"><path d="M480 160V64h64v96h-64z m180.288 31.168l48-83.136 55.424 32-48 83.136-55.424-32zM512 736a224 224 0 1 0 0-448 224 224 0 0 0 0 448z m0 64a288 288 0 1 0 0-576 288 288 0 0 0 0 576z m352-320h96v64h-96v-64z m19.968-219.712l-83.136 48 32 55.424 83.136-48-32-55.424zM260.288 140.032l48 83.136 55.424-32-48-83.136-55.424 32z m-69.12 223.68l-83.136-48 32-55.424 83.136 48-32 55.424zM480 864v96h64v-96h-64zM160 480H64v64h96v-64z m-51.968 228.288l83.136-48 32 55.424-83.136 48-32-55.424z m200.256 92.544l-48 83.168 55.424 32 48-83.168-55.424-32z m400 115.168l-48-83.168 55.424-32 48 83.168-55.424 32z m92.544-200.288l83.136 48 32-55.424-83.136-48-32 55.424z" p-id="5571" fill="#71717a"></path></svg>
        </div>
        <div class="svg-box mr" v-if="themeVal == 'light'" @click="changeTheme('dark')">
          <svg t="1731671503353" class="toprightSVG"  viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4338" width="20" height="20"><path d="M644.5056 70.528C834.4064 127.488 972.8 303.5648 972.8 512c0 254.4896-206.3104 460.8-460.8 460.8-222.4128 0-408.0128-157.568-451.2768-367.1296A433.4848 433.4848 0 0 0 230.4 640c240.3584 0 435.2-194.8416 435.2-435.2 0-44.2112-6.5792-86.8608-18.8416-127.0528z" p-id="4339" fill="#71717a"></path></svg>
        </div>
        <!-- 语言 -->
        <div class="svg-box mr">
          <el-dropdown trigger="hover" style="float: right">
            <span class="el-dropdown-link">
              <svg t="1731672005757" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6711" data-spm-anchor-id="a313x.search_index.0.i13.5bb33a81qvpsBb" width="20" height="20"><path d="M165.302857 405.357714h62.756572c1.755429 0 3.437714 0.731429 4.681142 1.974857a7.168 7.168 0 0 1 0 9.801143L124.342857 530.285714a9.654857 9.654857 0 0 1-14.043428 0L1.974857 417.133714A7.094857 7.094857 0 0 1 0 412.233143c0-3.803429 2.925714-6.875429 6.582857-6.875429h70.217143a448.146286 448.146286 0 0 1 870.4 0h-88.502857a362.788571 362.788571 0 0 0-693.394286 0z m693.394286 213.284572h-62.756572a6.802286 6.802286 0 0 1-6.582857-6.875429c0-1.901714 0.731429-3.657143 1.901715-4.900571L899.657143 493.714286a9.654857 9.654857 0 0 1 14.043428 0l108.324572 113.152a7.168 7.168 0 0 1 0 9.801143 6.509714 6.509714 0 0 1-4.681143 1.974857h-70.144a448.146286 448.146286 0 0 1-870.4 0h88.502857a362.788571 362.788571 0 0 0 693.394286 0zM469.796571 320h85.577143l148.626286 384H619.300571l-47.542857-105.984H449.682286l-43.739429 105.984H320l149.796571-384zM471.771429 512h79.945142l-39.058285-107.52L471.771429 512z" fill="#71717a" p-id="6712"></path></svg>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="clearfix">
                <div class="noticeList" @click="langChange('cn')">
                  中文简体
                </div>
              </el-dropdown-item>
              <el-dropdown-item class="clearfix">
                <div class="noticeList" @click="langChange('en')">
                  Enghlish
                </div>
              </el-dropdown-item>
              <el-dropdown-item class="clearfix">
                <div class="noticeList" @click="langChange('hk')">
                  繁體中文
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- 日历 -->
        <!-- <div class="svg-box mr" @mouseenter="calenderOpen" @mouseleave="calendarClose">
          <img src="../images/svg/calendar.svg" alt="" srcset="" />
          <transition name="cal">
            <div class="calendar_box" v-show="calendarVisible">
              <CalendarCom></CalendarCom>
            </div>
          </transition>
        </div> -->
        <!-- 公告 -->
        <div class="svg-box mr">
          <el-dropdown trigger="hover" style="float: right">
            <span class="el-dropdown-link">
              <el-badge :value="noticeHtml.length" class="item">
                <svg t="1731672063315" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8834" width="20" height="20"><path d="M588.729556 225.13839a43.378932 43.378932 0 0 0-41.262005-3.200677L269.073647 346.294504h-169.137374a43.350032 43.350032 0 0 0-43.350032 43.350031v259.095915a43.350032 43.350032 0 0 0 43.350032 43.350032h169.137374l278.401129 124.378466a43.277782 43.277782 0 0 0 41.262005-3.222352 43.299457 43.299457 0 0 0 19.767615-36.363452V261.516292a43.357257 43.357257 0 0 0-19.77484-36.377902zM932.155733 476.626374h-179.295732a43.350032 43.350032 0 0 0 0 86.700064h179.295732a43.350032 43.350032 0 1 0 0-86.700064zM727.334058 412.865702a43.118832 43.118832 0 0 0 23.705242-7.08773l148.025908-96.988471a43.350032 43.350032 0 1 0-47.518859-72.517378L703.527665 333.253369a43.350032 43.350032 0 0 0 23.806393 79.612333zM899.065208 731.163311L751.0393 634.182064a43.350032 43.350032 0 0 0-47.51886 72.524604l148.025909 96.981246a43.299457 43.299457 0 0 0 60.010894-12.49926 43.350032 43.350032 0 0 0-12.492035-60.025343z" fill="#71717a" p-id="8835"></path></svg>
              </el-badge>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="clearfix" v-for="(d, index) in noticeHtml" :key="index">
                <div class="noticeList" @click="openNoticeInfo(d)">
                  <div class="svg-no">
                    <img src="../images/svg/tzl.svg" alt="" />
                  </div>
                  <div class="notice-content" v-html="d">
                  </div>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- 移动端 -->
        <!-- <div class="svg-box mr" @click="qrCodeOpneModal(1)">
          <img src="../images/svg/phone.svg" alt="" srcset="" />
        </div> -->
        <!-- 客服微信 -->
        <div class="svg-box aniSvg" @click="qrCodeOpneModal(0)">
          <svg t="1731672093991" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9984" width="20" height="20"><path d="M896 426.666667a384 384 0 1 0-768 0h85.333333a298.666667 298.666667 0 1 1 597.333334 0h85.333333zM896 938.666667a384 384 0 1 0-768 0 42.666667 42.666667 0 0 0 85.333333 0 298.666667 298.666667 0 1 1 597.333334 0 42.666667 42.666667 0 0 0 85.333333 0z" p-id="9985" fill="#71717a"></path><path d="M512 640a213.333333 213.333333 0 1 0 0-426.666667 213.333333 213.333333 0 0 0 0 426.666667z m0-85.333333a128 128 0 1 1 0-256 128 128 0 0 1 0 256zM789.333333 362.666667v120.32a64 64 0 0 0 128 0V362.666667a64 64 0 1 0-128 0zM106.666667 341.333333v141.653334a64 64 0 0 0 128 0V341.333333a64 64 0 0 0-128 0z" p-id="9986" fill="#71717a"></path></svg>
        </div>
      </div>
    </div>
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="container_4data" ref="data4">
          <!-- title -->
          <div class="container_4data-title">General Statistics</div>
          <!-- 数据宫格cell -->
          <div class="container_4data-cell">
            <!-- 单项 -->
            <div class="container_4data-cell-item">
              <div class="grid-content bg-purple">
                <div class="user_box">
                  <div style="float: right">
                    <div class="svg-c">
                      <img src="../images/svg/x_yh.svg" alt="" srcset="" />
                    </div>
                  </div>
                  <div style="
                      float: left;
                      font-size: 14px;
                      color: #67748e;
                      font-weight: 600;
                    ">
                    User <br /><span>
                      <span style="
                          font-size: 22px;
                          color: var(--mainText);
                          font-weight: 700;
                        ">{{ user }}</span>
                    </span>
                  </div>
                  <div style="clear: both"></div>
                </div>
              </div>
            </div>
            <!-- 单项 -->
            <div class="container_4data-cell-item">
              <div class="grid-content bg-purple" @click="copyUrl()">
                <div class="user_box">
                  <div style="float: right">
                    <div class="svg-c">
                      <img src="../images/svg/yqm.svg" alt="" srcset="" />
                    </div>
                  </div>
                  <div style="
                      float: left;
                      font-size: 14px;
                      color: #67748e;
                      font-weight: 600;
                    ">
                    Invitation code <br /><span>
                      <span style="
                          font-size: 22px;
                          color: var(--mainText);
                          font-weight: 700;
                        ">{{ nums }}</span>
                    </span>
                  </div>
                  <div style="clear: both"></div>
                </div>
              </div>
            </div>
            <!-- 单项 -->
            <div class="container_4data-cell-item">
              <div class="grid-content bg-purple">
                <div class="user_box">
                  <div style="float: right">
                    <div class="svg-c">
                      <img src="../images/svg/ye.svg" alt="" srcset="" />
                    </div>
                  </div>
                  <div style="
                      float: left;
                      font-size: 14px;
                      color: #67748e;
                      font-weight: 600;
                    ">
                    Balance<br /><span>
                      <span style="
                          font-size: 22px;
                          color: var(--mainText);
                          font-weight: 700;
                        ">{{ usd }}$</span>
                    </span>
                  </div>
                  <div style="clear: both"></div>
                </div>
              </div>
            </div>
            <!-- 单项 -->
            <div class="container_4data-cell-item">
              <div class="grid-content bg-purple" @click="tzQd()">
                <div class="user_box">
                  <div style="float: right">
                    <div class="svg-c">
                      <img src="../images/svg/c.svg" alt="" srcset="" />
                    </div>
                  </div>
                  <div style="
                      float: left;
                      font-size: 14px;
                      color: #67748e;
                      font-weight: 600;
                    ">
                    Number of cards <br /><span>
                      <span style="
                          font-size: 22px;
                          color: var(--mainText);
                          font-weight: 700;
                        ">{{ cardnum }}</span>
                    </span>
                  </div>
                  <div style="clear: both"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- 数据列表 -->
          <div class="container_4data-list" @click="toAd">
            <div class="container_4data-list-title">Country Ads Tata</div>
            <div class="container_4data-list-country">
              <div class="container_4data-list-country-c">
                <div class="img-country">
                  <img src="../images/US.png" alt="" srcset="" />
                </div>
                <div class="content">
                  <div>Country:</div>
                  <div>United States</div>
                </div>
              </div>

              <div class="container_4data-list-country-c">
                <div class="content-right">
                  <div>Bounce:</div>
                  <div>29.9%</div>
                </div>
              </div>
            </div>
            <div class="container_4data-list-country">
              <div class="container_4data-list-country-c">
                <div class="img-country">
                  <img src="../images/DE.png" alt="" srcset="" />
                </div>
                <div class="content">
                  <div>Country:</div>
                  <div>Germany</div>
                </div>
              </div>

              <div class="container_4data-list-country-c">
                <div class="content-right">
                  <div>Bounce:</div>
                  <div>40.22%</div>
                </div>
              </div>
            </div>
            <div class="container_4data-list-country">
              <div class="container_4data-list-country-c">
                <div class="img-country">
                  <img src="../images/GB.png" alt="" srcset="" />
                </div>
                <div class="content">
                  <div>Country:</div>
                  <div>Great Britain</div>
                </div>
              </div>

              <div class="container_4data-list-country-c">
                <div class="content-right">
                  <div>Bounce:</div>
                  <div>23.44%</div>
                </div>
              </div>
            </div>
            <div class="container_4data-list-country" style="border: none">
              <div class="container_4data-list-country-c">
                <div class="img-country">
                  <img src="../images/BR.png" alt="" srcset="" />
                </div>
                <div class="content">
                  <div>Country:</div>
                  <div>Brasil</div>
                </div>
              </div>

              <div class="container_4data-list-country-c">
                <div class="content-right">
                  <div>Bounce:</div>
                  <div>32.14%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="adbox">
          <earth v-if="earthVisible" :width="earthWidth" :height="earthHight"></earth>
          <!-- <img class="advantage-element_earth ab" src="@/images/welcome/advantage-element_earth.png" alt="">   -->
          <img class="advantage-element-01 ab" src="@/images/welcome/advantage-element-01.png" alt="">
          <img class="advantage-element-02 ab" src="@/images/welcome/advantage-element-02.png" alt="">
          <img class="advantage-element-03 ab" src="@/images/welcome/advantage-element-03.png" alt="">
          <img class="advantage-element-04 ab" src="@/images/welcome/advantage-element-04.png" alt="">
          <img class="advantage-element-05 ab" src="@/images/welcome/advantage-element-05.png" alt="">
          <img class="advantage-element-06 ab" src="@/images/welcome/advantage-element-06.png" alt="">
          <img class="advantage-element-07 ab" src="@/images/welcome/advantage-element-07.png" alt="">
          <img class="advantage-element-08 ab" src="@/images/welcome/advantage-element-08.png" alt="">
          <img class="advantage-element-09 ab" src="@/images/welcome/advantage-element-09.png" alt="">
          <img class="advantage-element-10 ab" src="@/images/welcome/advantage-element-10.png" alt="">
          <img class="advantage-element-11 ab" src="@/images/welcome/advantage-element-11.png" alt="">
        </div>
        <!-- <earth v-if="earthVisible" :width="earthWidth" :height="earthHight"></earth> -->
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="11">
        <div class="grid-content bg-purple gg_box">
          <div class="charts-left-c">
            <div class="mid-c">
              <div class="totalTitle">Failure Rate</div>
              <el-tooltip class="item" effect="dark" content="账户总拒付率" placement="top">
                <div class="totalper" :style="{
                  color: parseFloat(this.relv) > 30 ? 'rgb(197, 25, 25)' : 'rgb(78, 184, 16)'
                }">({{ this.relv }}%)</div>
              </el-tooltip>
              <!-- <div>(+23%) than last week</div> -->
            </div>
            <div class="top-charts">
              <div id="chart1" style="height: 360px; width: 100%"></div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="13">
        <div class="grid-content bg-purple gg_box">
          <div class="charts-left-c">
            <div class="title-right1">Card data</div>
            <div class="bottom-charts">
              <div id="chart2" style="width: 100%; height: 320px"></div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 弹窗公告 -->
    <div id="ofBar" v-if="ofbarVisible">
      <div id="ofBar-logo">
        <img style="width: 50px; height: 50px" alt="creative-tim-logo" src="@/images/logo-ct-black.png" />
        <b style="margin-left: 12px">{{ this.alertnot.title }}</b>
      </div>
      <div id="ofBar-content">
        <!-- <b></b> -->
        <!-- {{ this.alertnot.content }} -->
        <span class="contentScroll" v-html="alertnot.content">
        </span>
      </div>
      <div id="ofBar-right">
        <div class="more_text" @click="moreContent = true">显示更多</div>
        <div id="close-bar" @click="closeOfBar">×</div>
      </div>
    </div>
    <!-- 显示更多公告 -->
    <el-dialog :visible.sync="moreContent" width="20%">
      <div style="text-align: center" v-html="alertnot.content">
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import * as echarts from "echarts";
import home from "../components/home.vue";
import Earth from "./cavas/earth.vue";
import CalendarCom from "./tools/calendar.vue";
import { boolean } from "mathjs";
export default {
  components: {
    Earth,
    CalendarCom,
  },
  data() {
    return {
      tjtdata: [],
      jldlog: false,
      gg_1: "",
      gg_2: "",
      gg_3: "",
      nums: "",
      notifyPromise: Promise.resolve(),
      name: "Welcome",
      ofbarVisible: false, // 公告弹窗
      userName: "",
      noticeData: [],
      user: this.$cookie.get("user"),
      isShow: this.$cookie.get("isShow"),
      alertnotice: [],
      alertnot: {
        title: "",
        content: "",
      },
      switchBtn: false, // 切换按钮
      calendarVisible: false, // 日历弹窗显示
      invati: this.$cookie.get("invite"),
      noticeHtml: [],
      cny: "",
      timer: null,
      usd: "",
      cardnum: "",
      activeNames: ["1"],
      usd_disabled_mon: "0.00",
      cny_disabled_mon: "0.00",
      disData: [],
      relv: "",
      remoney: "",
      qrcodeVisible: 0, // 二维码显示 0客服 1手机
      earthVisible: false, // 3d球显示
      earthWidth: 0,
      earthHight: 0,
      moreContent: false, // 显示全部公告
      noticeInfo: "", // 点击公告的详细信息
      TcNoticeList: [], // 弹窗列表
      wechatUrl: require('../images/wx_zxkf.jpg'),
      languageVal: this.$i18n.locale,
      themeVal: ''
    };
  },
  methods: {
    // 主题切换
    changeTheme(value) {
      document.documentElement.setAttribute('theme', value)
      localStorage.setItem('theme', value)
      this.themeVal = value
      // 如果需要返回到之前的主题
      this.loadCSS(value);

    },
    loadCSS(value) {
      this.removeCSS(value)
      var cssLink = document.createElement("link");
      cssLink.rel = "stylesheet";
      cssLink.type = "text/css";
      // cssLink.href = `/register/theme/${value}/index.css`;
      cssLink.href = this.$csslink + `/theme/${value}/index.css`;
      cssLink.id = `theme-css-${value}`; // 设置唯一 ID
      document.getElementsByTagName("head")[0].appendChild(cssLink);
    },
    removeCSS(themeName) {
      var link = document.getElementById(`theme-css-${themeName}`);
      if (link) {
        link.parentNode.removeChild(link);
      }
    },
    //语言切换
    langChange(e) {
      localStorage.setItem('lang', e);
      this.$i18n.locale = e;
      window.location.reload()
    },
    toAd() {
      this.$router.push('/advertising')
    },
    // 关{闭日历弹窗
    calendarClose() {
      this.calendarVisible = false;
    },
    // 打开日历弹窗
    calenderOpen() {
      this.calendarVisible = true;
    },
    // 二维码弹窗
    qrCodeOpneModal(index) {
      this.qrcodeVisible = index;
      this.noticeInfo = "";
      this.jldlog = true;
    },
    // 打开详细弹窗
    openNoticeInfo(content) {
      this.noticeInfo = content;
      this.qrcodeVisible = -1;
      this.jldlog = true;
    },
    handleClose() {
      this.jldlog = false;
      this.qrcodeVisible = -1;
      // 配合关闭弹窗的动画
      setTimeout(() => {
        this.noticeInfo = "";
      }, 800);
    },
    jftz() {
      this.$router.push("/dishonourManage");
    },
    // 加载chart2表格数据
    loadChart2() {
      var chartDom = document.getElementById("chart2");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        dataset: {
          source: [],
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
            },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            show: false, // 隐藏 x 轴线
          },
          axisTick: {
            show: false, // 隐藏 x 轴刻度线
          },
          axisLabel: {
            padding: [10, 0, 0, 0], // 设置上边距为 10px，其余边距为 0
            interval: 4, // 设置为 4，即每隔 4 个标签显示 1 个
            // ... 其他配置项
          },
        },
        yAxis: {
          type: "value",
          axisTick: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              type: "dashed", // 设置刻度线为虚线
            },
            show: false
          },
        },
        grid: {
          top: "5%",
          left: "5%",
          right: "5%",
          bottom: "10%",
        },
        series: [
          {
            name: "消费成功金额",
            color: "rgba(216,61,215)",
            type: "line",
            smooth: true,
            symbol: "none", // 去除小圆点
            lineStyle: {
              color: "rgba(216,61,215)",
              width: 4, // 设置线粗为 3
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  { offset: 0, color: "rgba(216, 61, 215, 0.3)" },
                  { offset: 1, color: "rgba(216, 61, 215, 0)" },
                ],
              },
            },
          },
          {
            name: "消费失败金额",
            color: "rgba(0,0,0)",
            type: "line",
            smooth: true,
            symbol: "none", // 去除小圆点
            lineStyle: {
              color: "rgba(0,0,0)",
              width: 4, // 设置线粗为 3
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  { offset: 0, color: "rgba(0, 0, 0, 0.3)" },
                  { offset: 1, color: "rgba(0, 0, 0, 0)" },
                ],
              },
            },
          },
        ],
      };
      // 表格数据
      let params = {};
      params = this.$lmjm("");
      const result = this.$http.post("/api/v1/getUserDishonoursday", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          if (res.data.code == "200") {
            option.dataset.source = res.data.data.data;
            // var pro = ["product", "消费成功金额", "消费失败金额"];
            // option.dataset.source.unshift(pro);
            if (res.data.data.data.length > 1) {
              // 绘制图表
              myChart.setOption(option);
            } else {
              option.title = {
                text: "暂无数据",
                x: "center",
                y: "center",
                textStyle: {
                  fontSize: 14,
                  fontWeight: "normal",
                },
              };
              myChart.setOption(option);
            }
          }
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
      option && myChart.setOption(option);
    },
    // 查询拒付数据
    getFuseData() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getRefuseRecord", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          const dataList = res.data.data.data;
          const card_data = dataList.map(item => {
            return {
              name: item.nums,
              value: parseFloat(item.lv),
              errmoney: item.errmoney,
              renmoney: item.renmoney
            }
          })
          this.loadChart1(card_data)
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 加载cahrt1表格数据
    loadChart1(dataList) {
      var chartDom = document.getElementById("chart1");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        // backgroundColor: "#fff",
        grid: {
          top: "10%",
          right: "5%",
          left: "8%",
          bottom: "12%",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
            },
          },
          formatter: function (params) {
            const { name, value, data } = params[0]; // 获取当前项的数据
            const errmoney = data.errmoney;
            const renmoney = data.renmoney;
            return `
                  <strong >失败金额:</strong> $${renmoney} <br/>
                  <strong>退款金额:</strong> $${errmoney}`; // 使用 <strong> 标签加粗文本
          }
        },
        xAxis: [
          {
            type: "category",
            color: "#59588D",
            data: dataList.map(item => item.name),
            axisLabel: {
              margin: 20,
              color: "#999",
              textStyle: {
                fontSize: 14,
              },
            },
            axisLine: {
              lineStyle: {
                color: "rgba(107,107,107,0.37)",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            axisLabel: {
              formatter: "{value}%",
              color: "#999",
              textStyle: {
                fontSize: 14,
              },
            },
            axisLine: {
              lineStyle: {
                color: "rgba(107,107,107,0.37)",
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                color: "rgba(131,101,101,0.2)",
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: dataList,
            barWidth: "50px",
            itemStyle: {
              normal: {
                color: function (params) {
                  let colorArr =
                    params.value > 0
                      ? ["#FF9A22", "#FFD56E"]
                      : ["#FFD56E", "#FF9A22"];
                  return new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: colorArr[0], // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: colorArr[1], // 100% 处的颜色
                      },
                    ],
                    false
                  );
                },
                barBorderRadius: [30, 30, 0, 0],
              },
            },
            label: {
              normal: {
                show: true,
                fontSize: 16,
                fontWeight: "bold",
                color: "#333",
                position: "top",
                formatter: function (params) {

                  return `${params.value}%`; // 显示值和百分比
                },
              },
            },
          },
          {
            data: dataList.map(item => item.value - 3),
            type: "line",
            smooth: true,
            name: "折线图",
            symbol: "none",
            lineStyle: {
              color: "#3275FB",
              width: 4,
              shadowColor: "rgba(0, 0, 0, 0.3)", //设置折线阴影
              shadowBlur: 15,
              shadowOffsetY: 20,
            },
          },
        ],
      };
      // option.dataset.source = dataList;
      option && myChart.setOption(option);
    },
    // 格式化时间
    getTime(/** timestamp=0 **/) {
      const ts = arguments[0] || 0;
      let t, y, m, d, h, i, s;
      t = ts ? new Date(ts * 1000) : new Date();
      y = t.getFullYear();
      m = t.getMonth() + 1;
      d = t.getDate();
      h = t.getHours();
      i = t.getMinutes();
      s = t.getSeconds();
      // 可依据须要在这里定义时间格式
      return (
        y +
        "-" +
        (m < 10 ? "0" + m : m) +
        "-" +
        (d < 10 ? "0" + d : d) +
        " " +
        (h < 10 ? "0" + h : h) +
        ":" +
        (i < 10 ? "0" + i : i) +
        ":" +
        (s < 10 ? "0" + s : s)
      );
    },
    getInfo() {
      let xj = 0;
      let params = {};
      params = this.$lmjm("");
      const result = this.$http.post("/api/v1/gethomedata", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.cny = res.data.data.cny;
          this.usd = res.data.data.usd;
          this.cardnum = res.data.data.cardnum;
          this.userName = res.data.data.username;
          this.noticeData = res.data.data.notice;
          this.alertnotice = res.data.data.alertnotice;
          this.usd_disabled_mon = res.data.data.usd_disabled_mon;
          this.cny_disabled_mon = res.data.data.cny_disabled_mon;

          for (let i = 0; i < this.noticeData.length; i++) {
            if (this.noticeData[i].type == "1") {
              this.noticeHtml[xj] = this.noticeData[i].content;
              xj = xj + 1;
            }
          }
          this.getTcNotice();
        },
        (error) => {
          console.log("错误:" + error);
        }
      );
    },
    // 关闭公告
    closeOfBar() {
      this.ofbarVisible = false;
      // 延迟第二条弹窗
      setTimeout(() => {
        const currentIndex = this.TcNoticeList.findIndex(
          (item) => item.content == this.alertnot.content
        );
        // 删掉已经显示的
        this.TcNoticeList.splice(currentIndex, 1);
        // 如果还有则继续显示第二条，没有则不再弹窗
        if (this.TcNoticeList.length > 0) {
          const tcN = this.TcNoticeList[0];
          this.alertnot.title = tcN.title;
          this.alertnot.content = tcN.content;
          this.ofbarVisible = true;
          // 判断是否需要滚动
          this.$nextTick(() => {
            this.checkContentWidth()
          })
        } else if (this.TcNoticeList.length == 0) {
          this.isShow = "false";
          document.cookie = "isShow=false";
        }
      }, 500);
      // document.cookie = "isShow=false";
    },
    // 获取弹窗与公告
    getTcNotice() {
      for (let i = 0; i < this.alertnotice.length; i++) {
        if (this.alertnotice[i].type == "2" && this.isShow == "true") {
          this.TcNoticeList.push(this.alertnotice[i]);
        }
      }
      if (this.TcNoticeList.length > 0) {
        const tcN = this.TcNoticeList[0];
        this.alertnot.title = tcN.title;
        this.alertnot.content = tcN.content;
        this.ofbarVisible = true;
        // 判断是否需要滚动
        this.$nextTick(() => {
          this.checkContentWidth()
        })
      }
    },
    checkContentWidth() {
      const container = this.$el.querySelector('#ofBar-content');
      const content = container.querySelector('.contentScroll');
      if (content.scrollWidth > container.clientWidth / 2) {
        content.classList.add('scrollable');
      } else {
        content.classList.remove('scrollable');
      }
    },
    // close
    coseAlert(id) {
      let params = {
        id: id,
      };
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/setNotShowNotice", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          console.log(res);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 获取个人中心数据
    getuserinfos() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.post("/api/v1/getuserinfos", params, {
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      result.then(
        (res) => {
          this.remoney = res.data.data.remoney;
          this.relv = res.data.data.relv;
          this.nums = res.data.data.nums;
          if (res.data.data.wechat_qrcode && res.data.data.wechat_qrcode != " ") {
            const fileList = JSON.parse(res.data.data.wechat_qrcode);
            this.wechatUrl = fileList[0].url;
          }
          this.getFuseData()
        },
        (error) => {
          console.log(error);
        }
      );
    },
    tzQd() {
      this.$router.push("/queryCard");
    },
    mgt() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("mgt"));
      var option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "bottom",
        },
        toolbox: {
          show: true,
        },
        series: [
          {
            type: "pie",
            center: ["50%", "50%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 8,
            },
            data: [
              {
                value: 40,
                name: "Dhgate",
              },
              {
                value: 38,
                name: "Chatgtp",
              },
              {
                value: 32,
                name: "FaceBook",
              },
              {
                value: 30,
                name: "Aliexpress",
              },
              {
                value: 28,
                name: "Amazon",
              },
              {
                value: 28,
                name: "Midjourney ",
              },
              {
                value: 28,
                name: "Etsy ",
              },
              {
                value: 28,
                name: "TikTok ",
              },
            ],
          },
        ],
      };
      // 绘制图表
      myChart.setOption(option);
    },
    sjch() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("bzt"));
      var option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          data: ["占比"],
          top: "bottom",
        },
        radar: {
          // shape: 'circle',
          indicator: [
            {
              name: "电商",
              max: 6500,
            },
            {
              name: "航旅",
              max: 8450,
            },
            {
              name: "游戏",
              max: 30000,
            },
            {
              name: "广告",
              max: 38000,
            },
            {
              name: "购物",
              max: 52000,
            },
            {
              name: "企业",
              max: 25000,
            },
          ],
        },
        series: [
          {
            type: "radar",
            areaStyle: {},
            data: [
              {
                value: [4200, 3000, 20000, 35000, 50000, 18000],
                name: "占比",
              },
            ],
          },
        ],
      };
      // 绘制图表
      myChart.setOption(option);
    },
    tjt() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("tjt"));
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        xAxis: {},
        yAxis: {
          type: "category",
          data: [],
        },
        series: [
          {
            type: "bar",
            data: [],
          },
        ],
      };
      if (this.disData.length > 0) {
        for (var i = 0; i < this.disData.length; i++) {
          option.yAxis.data[i] = this.disData[i].nums;
          option.series[0].data[i] = this.disData[i].allmoney;
        }
        myChart.hideLoading();
        // 绘制图表
        myChart.setOption(option);
      } else {
        myChart.showLoading({
          text: "暂无数据",
          color: "#aa00ff",
          textColor: "#aa55ff",
          maskColor: "rgba(255, 255, 255, 0.8)",
          zlevel: 0,
        });
      }
    },
    copyUrl() {
      const item =
        "https://www.limaopay.com/register/#/register?codes=" + this.nums;
      const cInput = document.createElement("input");
      cInput.style.display = "";
      cInput.value = item;
      document.body.appendChild(cInput);
      cInput.select(); // 选取文本框内容
      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效
      document.execCommand("copy");
      this.$message({
        type: "success",
        message: "邀请链接复制成功!",
      });
    },
  },
  mounted: function () {
    // this.mgt();
    // this.sjch();
    // this.tjt();
    let theme = localStorage.getItem('theme') ? localStorage.getItem('theme') : 'light'
    this.themeVal = theme
    this.loadChart2();
    this.$nextTick(() => {
      // 加载球体
      this.earthWidth = this.$refs.data4.clientWidth;
      this.earthHight = this.$refs.data4.clientHeight;
      this.earthVisible = true;
    });
  },
  created() {
    this.getInfo();
    this.getuserinfos();
  },
};
</script>

<style lang="less" scoped>
.cal-enter-active,
.cal-leave-active {
  transition: opacity 0.5s;
}

.cal-enter,
.cal-leave-to

/* .cal-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.user_title_tab {
  font-size: 1.5em;
  margin-bottom: 0.5em;
  // background-color: var(--bgColor);
  padding: 10px;
  // -webkit-box-shadow: 4px 4px 40px rgb(0 0 0 / 5%);
  // box-shadow: 4px 4px 40px rgb(0 0 0 / 5%);
  border-radius: 8px;
  display: flex;
}

.noticeList {
  display: flex;
  align-items: center;
  height: 40px;

  .svg-no {
    width: 30px;
    height: 30px;
    margin-right: 5px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .notice-content {
    width: 100px;
    overflow: hidden; // 溢出隐藏
    text-overflow: ellipsis; // 溢出用省略号显示
    white-space: nowrap; // 规定段落中的文本不进行换行
  }
}

.menu-container {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .aniSvg {
    animation: boundImg 800ms linear infinite;
  }

  .svg-box {
    position: relative;
    cursor: pointer;
    width: 25px;
    height: 25px;
    z-index: 9999;
    display: flex;
    justify-content: center;
    
  }

  .calendar_box {
    position: absolute;
    width: auto;
    height: auto;
    transform: translateX(-90%);
    border-radius: 8px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  .svg-box:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .mr {
    margin-right: 15px;
  }
}

.charts-left-c {
  width: 100%;
  height: 380px;
  font-family: pingfang;

  .top-charts {
    width: 100%;
    height: 340px;
    border-radius: 12px;
    // background-image: linear-gradient(310deg, #141727, #3a416f);
  }

  .bottom-charts {
    width: 100%;
    height: 332px;
    padding: 16px;
  }

  .mid-c {
    display: flex;
    // padding: 8px 0 0 24px;
    // margin-bottom: 25px;

    .totalTitle {
      font-size: 16px;
      color: #344767;
      font-weight: 600;
    }

    .totalper {
      color: rgb(78, 184, 16);
      margin-left: 15px;
      font-size: 16px;
      color: "67748e";
    }
  }

  .bottom-c {
    width: 100%;
    height: 104px;
    margin-top: 16px;
    display: flex;
    justify-content: space-around;

    .cell {
      height: 100%;
      width: 20%;
      padding: 16px 12px 16px 8px;

      .b1 {
        margin-bottom: 8px;
        display: flex;

        div {
          margin-left: 10px;
          font-size: 13px;
          height: 25px;
          line-height: 25px;
          color: #67748e;
          font-weight: 600;
          letter-spacing: 1px;
        }
      }

      .b2 {
        font-size: 24px;
        color: #344767;
        margin-bottom: 8px;
        font-weight: 900;
        letter-spacing: 0.5px;
      }

      .b3 {
        width: 100%;
        height: 20px;

        .progress-c {
          position: relative;
          width: 100%;
          height: 3px;
          background-color: #e9ecef;
          border-radius: 3px;
        }

        .progress-c::before {
          position: absolute;
          top: -2px;
          content: "";
          width: 60%;
          height: 6px;
          border-radius: 3px;
          background-color: #000;
        }
      }
    }
  }

  .title-right1 {
    font-size: 16px;
    color: #344767;
    font-weight: 700;
  }

  .title-right2 {
    font-size: 14px;
    color: #67748e;
  }
}

.earth-container {
  width: 100%;
  height: 646px;
}

.adbox {
  position: relative;
  height: 570px;
  border-radius: 12px;
}

.ab {
  position: absolute;
}

.advantage-element_earth {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: 1s ease 0s 1 normal forwards running content3d;
}

.advantage-element-01 {
  top: 3%;
  left: 5%;
  opacity: 0;
  animation: 1s ease 1000ms 1 normal forwards running content3d;
}

.advantage-element-02 {
  top: 3%;
  right: 45%;
  opacity: 0;
  animation: 1s ease 800ms 1 normal forwards running content3d;
}

.advantage-element-03 {
  top: 10%;
  left: 15%;
  opacity: 0;
  animation: 1s ease 1000ms 1 normal forwards running content3d;
}

.advantage-element-04 {
  top: 20%;
  left: 1%;
  opacity: 0;
  animation: 1s ease 900ms 1 normal forwards running content3d;
}

.advantage-element-05 {
  bottom: 10%;
  left: 5%;
  opacity: 0;
  animation: 1s ease 1200ms 1 normal forwards running content3d;
}

.advantage-element-06 {
  bottom: 10%;
  left: 25%;
  opacity: 0;
  animation: 1s ease 1100ms 1 normal forwards running content3d;
}

.advantage-element-07 {
  top: 30%;
  right: 35%;
  opacity: 0;
  animation: 1s ease 1400ms 1 normal forwards running content3d;
}

.advantage-element-08 {
  top: 45%;
  right: 15%;
  opacity: 0;
  animation: 1s ease 1300ms 1 normal forwards running content3d;
}

.advantage-element-09 {
  bottom: 5%;
  right: 25%;
  opacity: 0;
  animation: 1s ease 900ms 1 normal forwards running content3d;
}

.advantage-element-10 {
  top: 10%;
  right: 5%;
  opacity: 0;
  animation: 1s ease 1s 1 normal forwards running content3d;
}

.advantage-element-11 {
  bottom: 5%;
  right: 5%;
  opacity: 0;
  animation: 1s ease 800ms 1 normal forwards running content3d;
}

@keyframes content3d {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.container_4data {
  font-family: pingfang;

  &-cell {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;

    .container_4data-cell-item {
      width: 48%;
      margin-bottom: 24px;
    }
  }

  &-title {
    margin-bottom: 48px;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 1.5px;
  }

  &-list {
    height: 320px;
    background-color: var(--mainCard);
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
    border-radius: 1em;
    cursor: pointer;

    &-title {
      padding: 16px 0 0px 16px;
      font-size: 16px;
      font-family: pingfang;
      color: #344767;
      font-weight: 600;
      margin-bottom: 20px;
    }

    &-country {
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &-c {
        width: 25%;
        padding: 8px;
        display: flex;

        .img-country {
          margin-left: 8px;
          width: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .content {
          margin-left: 16px;

          div:nth-child(1) {
            font-size: 12px;
            color: #67748e;
          }

          div:nth-child(2) {
            font-size: 14px;
            color: #344767;
            font-weight: 600;
          }
        }

        .content-right {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          div:nth-child(1) {
            font-size: 12px;
            color: #67748e;
          }

          div:nth-child(2) {
            font-size: 14px;
            color: #344767;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.small {
  font-weight: 400 !important;
}

.qrcodebtn-container {
  position: fixed;
  right: 25px;
  bottom: 25vh;
  cursor: pointer;
  width: 70px;
  height: 70px;
  background: #7f5fef;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  color: #fff;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.qrcode-code {
  position: fixed;
  right: 120px;
  bottom: calc(25vh - 50px);
  width: 200px;
  height: 200px;
  border-radius: 8px;
  z-index: 99;
  border: 2px solid #475669;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qrcode-code>img {
  width: 95%;
  height: 95%;
}

.img-c {
  width: 60%;
  height: 60%;
}

.img-c>img {
  width: 100%;
  height: 100%;
}

.block {
  height: 300px;
}

body {
  overflow-y: hidden;
}

.xsq {
  width: 50px;
  height: 50px;
}

.svg-c {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-image: linear-gradient(310deg, #7928ca, #ff0080);
  border-radius: 8px;
}

.gg_box {
  padding: 1.3em 1em;
}

.gg_title {
  margin-bottom: 1em;
}

.el-collapse-item {
  font-size: 5em !important;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple-light {
  background: #e5e9f2;
}

.el-tag {
  font-size: 21px;
}

// svg {
//   width: 25px;
//   height: 25px;
//   margin-right: 5px;
//   position: relative;
//   top: 4px;
// }

/* 修改滚动条的轨道（滑道） */
::-webkit-scrollbar {
  width: 2px;
  /* 设置滚动条的宽度 */
}

/* 修改滚动条的轨道（滑道）背景颜色 */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* 修改滚动条的滑块（拖动条） */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* 修改滚动条的滑块（拖动条）悬停时的样式 */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
<style>
.el-submenu__title i {
  color: #fff !important;
}

button {
  border-radius: 10px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 30px;
  margin: 0;
  padding: 20px;
}

.el-carousel__item:nth-child(2n) {
  background-color: #ffffff;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #ffffff;
}

.el-carousel__button {
  background-color: #725cda !important;
  width: 10px !important;
  height: 10px !important;
  border-radius: 15px !important;
}

.el-carousel {
  border-radius: 10px;
}

#ofBar {
  background: #fff;
  z-index: 999999999;
  font-size: 16px;
  color: #333;
  padding: 11px 40px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 40px;
  width: 65%;
  border-radius: 8px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
}

#ofBar-logo {
  display: flex;
  align-items: center;
}

#ofBar-content {
  cursor: pointer;
  width: 60%;
  display: inline;
  overflow: hidden;
  white-space: nowrap;
}

#ofBar-content:hover .contentScroll {
  animation-play-state: paused;
  /* 鼠标悬停时暂停动画 */
}

.contentScroll {
  display: inline-block;
  white-space: nowrap;
  width: 100%;
  transform: translateX(10%);
  /* animation-play-state: paused; */
}

.scrollable {
  animation: scrolltext 9s linear infinite;
  animation-delay: 1.5s;
}

@keyframes scrolltext {
  0% {
    transform: translateX(10%);
  }

  100% {
    transform: translateX(-80%);
  }
}

.more_text {
  cursor: pointer;
  font-size: 12px;
  margin: 0 8px;
  letter-spacing: 1.5px;
  font-weight: 600;
  color: #fff;
  padding: 8px;
  border-radius: 12px;
  background-color: #bf2c9f;
}

#ofBar-right {
  width: 10%;
  display: flex;
  align-items: center;
}

#close-bar {
  cursor: pointer;
  font-size: 16px;
  font-weight: 800;
}


@keyframes boundImg {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-4px);
  }

  100% {
    transform: translateY(0);
  }
}
</style>
