<template>
  <div class="container">
    <div class="db-RegisterAndLoginBackground-background">
      <div class="db-RegisterAndLoginBackground-grayBackground"></div>
      <div class="db-RegisterAndLoginBackground-verticalLine db-RegisterAndLoginBackground-verticalLine--first"></div>
      <!-- <div class="db-RegisterAndLoginBackground-verticalLine db-RegisterAndLoginBackground-verticalLine--second"></div> -->
      <!-- <div class="db-RegisterAndLoginBackground-verticalLine db-RegisterAndLoginBackground-verticalLine--third"></div> -->
      <!-- <div class="db-RegisterAndLoginBackground-verticalLine db-RegisterAndLoginBackground-verticalLine--fourth"></div> -->
      <div class="db-RegisterAndLoginBackground-verticalLine db-RegisterAndLoginBackground-verticalLine--fifth"></div>
      <div class="db-RegisterAndLoginBackground-firstLeftStripe" style="background: rgb(128, 233, 255)"></div>
      <div class="db-RegisterAndLoginBackground-secondLeftStripe" style="background: rgb(122, 115, 255)"></div>
      <div class="db-RegisterAndLoginBackground-firstAndSecondLeftStripeBlend" style="background: rgb(0, 72, 229)">
      </div>
      <div class="db-RegisterAndLoginBackground-firstRightStripe" style="background: rgb(122, 115, 255)"></div>
    </div>
    <!-- 表单 -->
    <div class="db-RegisterAndLoginLayout-contentWrapper Box-root">
      <div class="db-RegisterAndLoginLayout-formContainer Box-root">
        <div class="db-RegisterAndLoginLayout-logo Box-root">
          <div title="Stripe"
            class="SVGInline SVGInline--cleaned SVG Logo Icon-color Icon-color--gray800 Box-root Flex-flex">
            <!-- LIMAO INC -->
          </div>
        </div>
        <!-- form -->
        <div class="db-RegisterAndLoginLayout-form">
          <!-- 登录表单 -->
           <div class="db-RegisterAndLoginLayout-form-row1">登录您的账户</div>
           <!-- 用户名 -->
            <div class="db-RegisterAndLoginLayout-form-row2">
              <div class="db-RegisterAndLoginLayout-form-row2-label">用户名</div>
              <el-input style="margin-top: 12px;" v-model="form_login.username"></el-input>
            </div>
             <!-- 密码 -->
            <div class="db-RegisterAndLoginLayout-form-row2 martop--32">
              <div class="db-RegisterAndLoginLayout-form-row2-label">
                <span>密码</span>
                <span style="color: #635bff;cursor: pointer;" @click="go_fg()">忘记密码?</span>
              </div>
              <el-input style="margin-top: 12px;" type="password" v-model="form_login.password" ></el-input>
            </div>
            <!-- 验证码 -->
            <div class="db-RegisterAndLoginLayout-form-row3 martop--32">
              <div class="db-RegisterAndLoginLayout-form-row3-label">
                <span>验证码</span>
              </div>
              <div class="db-RegisterAndLoginLayout-form-row3-code">
                <el-input style="margin-top: 12px;width: 45%;" v-model="form_login.code"></el-input>
                <img class="codeImg" :src="codeSrc" alt="" @click="getCodeUrl()">
              </div>
            </div>
            <!-- 按钮 -->
             <div class="db-RegisterAndLoginLayout-form-row4 ">
                <div class="db-RegisterAndLoginLayout-form-row4-btn" @click="login('form_login')">登录</div>
             </div>
             <!-- 注册 -->  
            <div class="db-RegisterAndLoginLayout-foot">
                <span>没有账户？</span><span @click="go_reg()" class="btn_foot">立即注册</span>
            </div>
        </div>
      
      </div>
    </div>
  </div>
</template>
<script>
import App from '@/App.vue';
export default {
  components: { App },
  data() {
    const ckUser = this.$cookie.get("user");
    return {
      codeSrc: "",
      isGG: true,
      GG_login: {
        user: this.$cookie.get("user"),
        codes: "",
      },
      // 表单数据绑定对象
      form_login: {
        uuid: "",
        username: this.$cookie.get("user"),
        password: "",
        code: "",
        token: "",
      },
      // 表单验证规则
      loginFormRules: {
        // 验证验证码
        code: [
          {
            required: true,
            message: this.$t("login.coderule"),
            tigger: "blur",
          },
        ],
        // 验证用户名
        username: [
          {
            required: true,
            message: this.$t("login.userrule"),
            tigger: "blur",
          },
          {
            min: 5,
            max: 12,
            message: this.$t("login.lengthrule"),
            trigger: "blur",
          },
        ],
        // 验证密码
        password: [
          {
            required: true,
            message: this.$t("login.pwdrule"),
            tigger: "blur",
          },
          {
            min: 5,
            max: 15,
            message: this.$t("login.lengthrule"),
            trigger: "blur",
          },
          {
            pattern: "^[0-9a-zA-Z_]{1,}$",
            message: this.$t("login.abcrule"),
          },
        ],
      },
      gg_loginFormRules: {
        // 验证用户名
        user: [
          {
            required: true,
            message: this.$t("login.userrule"),
            tigger: "blur",
          },
          {
            min: 5,
            max: 12,
            message: this.$t("login.lengthrule"),
            trigger: "blur",
          },
        ],
        // 验证验证码
        codes: [
          {
            required: true,
            message: this.$t("login.coderule"),
            tigger: "blur",
          },
          {
            min: 6,
            max: 6,
            message: this.$t("login.ggrule"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    ggl() {
      this.isGG = false;
    },
    // 跳转到注册页面
    go_reg() {
      this.$router.push("/register");
    },
    // 跳转到忘记密码页面
    go_fg() {
      this.$router.push("/forgotPW");
    },

    GGlogin() {
      let logins = {
        users: this.GG_login.user,
        code: this.GG_login.codes,
      };
      logins = this.$lmjm(logins);
      this.$refs.gg_form_loginFormRef.validate(async (valid) => {
        if (!valid) return this.$message.error("登录错误，请重新登录!");
        const result = this.$http.post("/api/v1/cclogin", logins);
        result.then(
          (res) => {
            console.log(res);
            if (res.data.code === 200) {
              // 获得成功响应返回的数据
              this.$message({
                message: "登录成功!",
                type: "success",
                duration: "800",
              });
              this.form_login.token = res.data.data.token;
              window.sessionStorage.setItem("token", this.form_login.token);
              this.$cookie.set("user", this.form_login.username);
              document.cookie = "isShow=true";
              this.VerifyToken(window.sessionStorage.getItem("token"));
            } else {
              this.$message.error(res.data.msg);
              this.getCodeUrl();
            }
          },
          (error) => {
            this.$message.error("登录错误，请重新登录!");
            this.getCodeUrl();
          }
        );
      });
    },
    login(form_login) {
      let logins = {
        users: this.form_login.username,
        pass: this.form_login.password,
        code: this.form_login.code.toLowerCase(),
        codeid: this.uuid,
      };
      logins = this.$lmjm(logins);
      if(!this.form_login.username){
        this.$message({
          message: "请输入用户名",
          type: "error",
          duration: "800",
        });
        return
      }
      if(!this.form_login.password){
        this.$message({
          message: "请输入密码",
          type: "error",
          duration: "800",
        });
        return
      }
      if(!this.form_login.code){
        this.$message({
          message: "请输入验证码",
          type: "error",
          duration: "800",
        });
        return
      }
      const result = this.$http.post("/api/v1/login", logins);
      result.then(
        (res) => {
          if (res.data.code === 200) {
            let params = {};
            params = this.$lmjm(params);
            const result = this.$http.post("/api/v1/getuserinfos", params, {
              headers: {
                token: res.data.data.token,
              },
            });
            result.then(
              (res) => {
                // 获得成功响应返回的数据
                this.$message({
                  message: "欢迎，" + res.data.data.realname + "。",
                  type: "success",
                  duration: "3000",
                });
              },
              (error) => {
                console.log(error);
              }
            );
            this.form_login.token = res.data.data.token;
            window.sessionStorage.setItem("token", this.form_login.token);
            this.$cookie.set("user", this.form_login.username);
            document.cookie = "isShow=true";
            this.VerifyToken(window.sessionStorage.getItem("token"));
          } else {
            this.$message.error(res.data.msg);
            this.getCodeUrl();
          }
        },
        (error) => {
          this.$message.error("登录错误，请重新登录!");
          this.getCodeUrl();
        }
      );
    },
    getCodeUrl() {
      let params = {};
      params = this.$lmjm(params);
      const result = this.$http.get(
        "/api/v1/captcha?" +
        "key=" +
        params.key +
        "&noncestr=" +
        params.noncestr +
        "&sign=" +
        params.sign +
        "&timestmap=" +
        params.timestmap
      );
      result.then(
        (res) => {
          if (res.status === 200) {
            // 获得成功响应返回的数据
            this.codeSrc = res.data.data.codeurl;
            this.uuid = res.data.data.codeid;
          }
        },
        (error) => {
          console.log("错误:" + error);
          this.getCodeUrl();
        }
      );
    },
    loadCSS(value) {
      this.removeCSS(value);
      var cssLink = document.createElement("link");
      cssLink.rel = "stylesheet";
      cssLink.type = "text/css";
      // cssLink.href = `/register/theme/${value}/index.css`;
      cssLink.href = this.$csslink + `/theme/${value}/index.css`;
      cssLink.id = `theme-css-${value}`; // 设置唯一 ID
      document.getElementsByTagName("head")[0].appendChild(cssLink);
    },
    removeCSS(themeName) {
      var link = document.getElementById(`theme-css-${themeName}`);
      if (link) {
        link.parentNode.removeChild(link);
      }
    },
  },
  mounted() {
    localStorage.setItem("theme", "light");
    document.documentElement.setAttribute("theme", "light");
    this.loadCSS("light");
  },
  created() {
    this.getCodeUrl();
    this.VerifyToken(window.sessionStorage.getItem("token"));

    function add_chatinline() {
      const hccid = 44569780;
      const nt = document.createElement("script");
      nt.async = true;
      nt.src = "https://mylivechat.com/chatinline.aspx?hccid=" + hccid;
      const ct = document.getElementsByTagName("script")[0];
      ct.parentNode.insertBefore(nt, ct);
    }
    add_chatinline();
  },
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  min-height: 100%;
  height: 100%;
  background-color: #fff;
  flex-direction: column;
  box-sizing: border-box;
}

.db-RegisterAndLoginBackground-background {
  z-index: 0;
  scrollbar-gutter: stable;
  width: 100vw;
  position: fixed;
  top: -250px;
  bottom: 0;
  left: 0;
  overflow: hidden;
  transform: skewY(-12deg);
}

.db-RegisterAndLoginBackground-grayBackground {
  transform-origin: 0%;
  height: 1698px;
  background-color: rgb(247, 250, 252);
  position: absolute;
  top: -1000px;
  left: 0;
  right: 0;
}

.db-RegisterAndLoginBackground-verticalLine--first {
  left: calc(50% - 540px);
}

.db-RegisterAndLoginBackground-verticalLine--fifth,
.db-RegisterAndLoginBackground-verticalLine--first {
  background: #eceef4;
}

.db-RegisterAndLoginBackground-verticalLine {
  width: 1px;
  background-image: linear-gradient(#eceef4 49%, #eceef400 50%);
  background-size: 1px 8px;
  position: absolute;
  top: -100vh;
  bottom: -100vh;
  right: auto;
}

.db-RegisterAndLoginBackground-verticalLine--second {
  left: calc(50% - 270px);
}

.db-RegisterAndLoginBackground-verticalLine--third {
  left: 50%;
}

.db-RegisterAndLoginBackground-verticalLine--fourth {
  left: calc(50% + 269px);
}

.db-RegisterAndLoginBackground-verticalLine--fifth {
  left: calc(50% + 540px);
}

.db-RegisterAndLoginBackground-verticalLine--fifth,
.db-RegisterAndLoginBackground-verticalLine--first {
  background: #eceef4;
}

.db-RegisterAndLoginBackground-firstLeftStripe {
  height: 40px;
  top: 668px;
  left: calc(50% - 700px);
  right: calc(50% + 540px);
}

.db-RegisterAndLoginBackground-firstAndSecondLeftStripeBlend,
.db-RegisterAndLoginBackground-firstLeftStripe,
.db-RegisterAndLoginBackground-firstRightStripe,
.db-RegisterAndLoginBackground-secondLeftStripe {
  position: absolute;
}

.db-RegisterAndLoginBackground-secondLeftStripe {
  height: 40px;
  top: 698px;
  left: -10px;
  right: calc(50% + 405px);
}

.db-RegisterAndLoginBackground-firstAndSecondLeftStripeBlend {
  height: 10px;
  top: 698px;
  left: calc(50% - 700px);
  right: calc(50% + 540px);
}

.db-RegisterAndLoginBackground-firstRightStripe {
  height: 40px;
  top: 658px;
  left: calc(50% + 430px);
  right: -10px;
}

.Box-root {
  box-sizing: border-box;
}

.db-RegisterAndLoginLayout-logo {
  padding-bottom: 40px;
  padding-left: 20px;
  font-size: 20px;
  font-weight: 900;
}

.Icon-color--gray800 {
  color: rgb(42, 47, 69);
}

.db-RegisterAndLoginLayout-contentWrapper {
  width: 1080px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.db-RegisterAndLoginLayout-formContainer {
  padding-top: 5%;
  width: 540px;
  min-width: 540px;
  display: flex;
  flex-direction: column;
}

.db-RegisterAndLoginLayout-form {
  position: relative;

  background: #fff;
  border-radius: 8px;
  box-shadow: 0 15px 35px 0 rgba(60, 66, 87, 0.08),
    0 5px 15px 0 rgba(0, 0, 0, 0.12);
  transform: translate3d(0px, 0px, 0);
  transform-origin: 0% 0%;
  width: 540px;
  min-width: 540px;
  height: 600px;
  will-change: transform, width, height;
  box-sizing: border-box;
  padding: 56px 48px;
}

.db-RegisterAndLoginLayout-form-row1{
  margin-top: 20px;
  margin-left: 20px;
  font-family: sohne-var, sohne-woff, Helvetica Neue, Arial, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -.2px;
  color: rgb(60,66,87);
}

.db-RegisterAndLoginLayout-form-row2{
  margin-top: 20px;
  margin-left: 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -.2px;
  color: rgb(60,66,87);
}

.db-RegisterAndLoginLayout-form-row3{
  margin-top: 20px;
  margin-left: 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -.2px;
  color: rgb(60,66,87);
}

.db-RegisterAndLoginLayout-form-row2-label{
  display: flex;
  justify-content: space-between;
}
.db-RegisterAndLoginLayout-form-row3-code{
  display: flex;
}

.codeImg {
  margin-left: 5%;
  margin-top: 12px;
  width: 50%;
  height: 40px;
  border-radius: 5px;
}

.db-RegisterAndLoginLayout-form-row4{
  margin-top: 20px;
  margin-left: 20px;
}
.db-RegisterAndLoginLayout-form-row4-btn{
  cursor: pointer;
  margin-top: 40px;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 800;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .2s ease-out;
  background-color: rgb(99, 91, 255);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(69, 56, 255, 0.8) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
}

.db-RegisterAndLoginLayout-form-row4-btn:hover{
  transform: translateY(-3px);
}

.db-RegisterAndLoginLayout-form-row4-btn:active{
  transform: translateY(3px);
}

.martop--32{
  margin-top: 32px;
}

.db-RegisterAndLoginLayout-foot{
  position: absolute;
  bottom: 5px;
  left: 5px;
  width: 98%;
  height: 50px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background: #f6f9fc;
  
}
.btn_foot{
  cursor: pointer;
  font-weight: 600;
  color: #635bff;
  transition: all .1s linear;
}
.btn_foot:hover{
  color: #000;
}
</style>
